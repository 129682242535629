import { FirebaseOptions } from '@angular/fire/app';

const options: FirebaseOptions = {
  apiKey: "AIzaSyAF-okyfDPM8DHnBZdpUTocjrImycizCOg",
  authDomain: "saap-logistics-test.firebaseapp.com",
  projectId: "saap-logistics-test",
  storageBucket: "saap-logistics-test.appspot.com",
  messagingSenderId: "384814814025",
  appId: "1:384814814025:web:c52fc82ffdd199d4360098",
  measurementId: "G-VL4ECPWQ6C"
} 

export const environment = {
  baseUrl: 'https://uat.saaplogistics.com/saap_api/',
  socketUrl: 'https://uat.saaplogistics.com/saap_api:3005',
  channelUrl: 'https://uat.saaplogistics.com/channel_api/',
  env: "uat",
  firebaseConfig: options
};
