<div class="container">
    <div class="row sub-header-buffer">
        <div class="col-md-6">
            <div class="input-group">
                <input type="text" class="form-control h-auto" [(ngModel)]="searchTxt" placeholder="Search by WayBill No" />
                <span class="input-group-btn search-border-style">
                    <button class="btn btn-default">
                        <i class="fa fa-xmark text-danger" (click)="clearSearch()"></i>
                    </button>
                </span>
            </div>
        </div>
        <div class="col-md-2">
            <button type="button" class="btn btn-ecom px-5" (click)="searchShipment()">
                Search
            </button>
        </div>
        <div class="col-md-2">
            <button type="button" class="btn btn-ecom px-5" (click)="refreshShipments()">
                Refresh
            </button>
        </div>
        <div class="col-md-2" *ngIf="any_selected">
            <button type="button" class="btn btn-ecom px-5" (click)="assignShipments()">
                Assign
            </button>
        </div>
    </div>
    <ul class="nav nav-tabs justify-content-end sub-header-buffer">
        <li class="nav-item">
            <a class="nav-link" (click)="onTabSelected('picked_from_customer')" role="tab" [ngClass]="setActiveTab('picked_from_customer')" href="javascript:void(0)">{{ tab_txt.picked_from_customer }}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="onTabSelected('rp_delivered')" role="tab" [ngClass]="setActiveTab('rp_delivered')" href="javascript:void(0)">{{ tab_txt.rp_delivered }}</a>
        </li>
    </ul>
    <div class="tab-content" id="nav-tabContent">
        <div role="tabpanel" class="tab-pane" [ngClass]="setActiveTab('picked_from_customer')" id="nav-picked-from-customer" aria-labelledby="nav-picked-from-customer-tab"><app-rp-list [shipments]="shipments" [totalItems]="totalItems" [shipmentType]="'picked_from_customer'"></app-rp-list></div>
        <div role="tabpanel" class="tab-pane" [ngClass]="setActiveTab('rp_delivered')" id="nav-rp-delivered" aria-labelledby="nav-rp-delivered-tab"><app-rp-list [shipments]="shipments" [totalItems]="totalItems" [shipmentType]="'rp_delivered'"></app-rp-list></div>
    </div>
</div>
<app-fcm-notification [params]="fcmNotificationParams"></app-fcm-notification>
