import { Component, Input, OnInit } from '@angular/core';
import { CONSTANTS } from '../../../constants';
import { DataService } from '../../../services/data.service';
import { JqueryUtils } from '../../../utils/jquery.utils';
import { AccountService } from '../../../services/api/account.service';
import { ShipmentService } from '../../../services/api/shipment.service';
import { NotificationService } from '../../../services/notification.service';
import { PrintService } from '../../../services/api/print.service';
import { ChannelService } from '../../../services/api/channel.service';

@Component({
  selector: 'app-fd-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Input('shipments')
  shipments: any = [];

  @Input('totalItems')
  totalItems = 0;

  @Input('shipmentType')
  shipmentType = '';

  @Input('tab')
  tab = '';

  sess_acc: any;
  token: any;
  offset = 0;

  tableParams: any = {
    has_actions: false
  };
  all_selected: any = false;
  any_selected: any = false;
  assignParams: any = { };
  viewBarcodeParams: any = { barcodes: [] };
  confirmationParams: any = { };
  coloaderParams: any = { };
  scanoutParams: any = { };
  errDisplayParams: any = { };

  coloaderModalName: any = '';
  slBarcodeModalName: any = '';
  assignSLDFEModalName: any = '';
  errorDisplayModalName: any = '';
  confirmModalName: any = '';
  subscriber_id: any = 'fdv_list';

  constructor(private accountService: AccountService, private channelService: ChannelService, private dataService: DataService,
    private shipmentService: ShipmentService, private ns: NotificationService,
    private printService: PrintService, private jq: JqueryUtils) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.token = sessionStorage.getItem(CONSTANTS.COOKIE.HEADER);
    this.coloaderModalName = `${this.shipmentType}_coloader_modal`;
    this.slBarcodeModalName = `${this.shipmentType}_slbarcode_modal`;
    this.assignSLDFEModalName = `${this.shipmentType}_assign_sl_dfe_modal`;
    this.errorDisplayModalName = `${this.shipmentType}_error_display_modal`;
    this.confirmModalName = `${this.shipmentType}_confirmation_modal`;
    this.dataService.onShipmentListTabSelected(this.subscriber_id).subscribe(res => {
      this.any_selected = false;
      this.all_selected = false;
      this.calculateTableParams(res.shipment_type);
    });
  }

  ngOnDestroy(): void {
    this.any_selected = false;
    this.all_selected = false;
    this.dataService.offShipmentListTabSelected(this.subscriber_id);
  }

  showOTP(shipment: any) {
    let ip: any = {
      barcode: shipment.details.bar_code
    };
    this.channelService.getOtp(ip).subscribe((response: any) => {
      alert(response.otps[0])
    });
  }

  assignExecutive(params: any) {

    let fe: any = this.getFEById(params.selExecId, params.fes);
    let ip: any = {
      assign_params: {
        order_id: [params.order_id]
      },
      fe_id: fe.user.user_id
    };
    this.shipmentService.assignShipment(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "FE Assigned successfully");
      this.dataService.shipmentListPageSelected({offset: this.offset, count: CONSTANTS.ITEM_PER_PAGE});
      this.jq.closeModal('assignSLFEModal');
    });
  }

  confirm(params: any) {

    if (params.from == 'cancel_shipment') {
      this.cancelShipment(params);
    } else if (params.from == 'unassign_de') {
      this.unassignShipment(params);
    } else if (params.from == 'scan_out') {
      let scan_out: any = {
        shipment: params.obj,
        np: params.obj.details?.arrived_at_destination_hub?.np,
        type: 'fe'
      };
      this.scanout(scan_out);
    }
  }

  cancelShipment(params: any) {

    let ip: any = {
      cancel_shipments: {
        barcodes: [params.barcode]
      }
    };
    this.shipmentService.cancelShipment(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "Shipment Cancelled successfully");
      this.dataService.shipmentListPageSelected({offset: this.offset, count: CONSTANTS.ITEM_PER_PAGE});
      this.jq.closeModal(this.confirmModalName);
    });
  }

  unassignShipment(params: any) {

    let ip: any = {
      unassign_params: {
        order_id: [params.order_id]
      }
    };
    this.shipmentService.unassignFE(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "Executive Unassigned successfully");
      this.dataService.shipmentListPageSelected({offset: this.offset, count: CONSTANTS.ITEM_PER_PAGE});
      this.jq.closeModal(this.confirmModalName);
    });
  }

  printBarcodes(barcodes: any) {
    this.viewBarcodeParams = { barcodes: [] };
    this.jq.closeModal(this.slBarcodeModalName);
    this.getBarcodes(barcodes[0].obj.id, (barcodess: any, shipments: any) => {
      let ip: any = { codes: barcodess };
      this.printService.printBarcodes(ip).subscribe((response: any) => {

      });
    });
  }

  addColoaderDetails(params: any) {

    let ip: any = {
      barcodes: [params.barcode],
      details: {
        name: params.name,
        phone: params.phone,
        city: params.city,
      }
    };
    this.shipmentService.saveColoaderDetails(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "Coloader saved successfully");
      this.jq.closeModal(this.coloaderModalName);
      this.dataService.shipmentListPageSelected({offset: this.offset, count: CONSTANTS.ITEM_PER_PAGE});
    });
  }

  getBarcodes(sid: any, cb: any) {
    let input: any = {
      shipment_ids: [sid]
    };
    this.shipmentService.getBarcodes(input).subscribe((response: any) => {
      let shipments: any = response.shipments;
      let barcodes: any = [];
      let shipment: any;
      for (let idx = 0; idx < shipments.length; idx++) {
        shipment = shipments[idx];
        for (let iidx = 0; iidx < shipment.total_boxes; iidx++) {
          barcodes.push({barcode: shipment.bar_code, obj: shipment, box_no: `${iidx+1}/${shipment.total_boxes}` });
        }
      }
      if (cb) {
        cb(barcodes, shipments);
      }
    });
  }

  scanout(params: any) {
    let np: any = null;
    if (params.type == 'np') {
      np = this.getNPById(params.np_id, params.nps);
      if (!np) {
        this.ns.showAlert(CONSTANTS.ERROR, "Please select atleast one Network Partner.");
        return;
      };
    } else {
      np = params.np;
    }
    let ip: any = {
      barcodes: [params.shipment.details.bar_code],
      np_id: np.user.user_id,
      force: 1
    };
    this.shipmentService.scanout(ip).subscribe((response: any) => {
      if (params.type == 'np') {
        this.jq.closeModal('scanoutModal');
      } else {
        this.jq.closeModal(this.confirmModalName);
      }
      this.openErrorDisplayModal('scanout', response);
      this.dataService.shipmentListPageSelected({offset: this.offset, count: CONSTANTS.ITEM_PER_PAGE});
    });
  }

  assignDeliveryExecutive(params: any) {

    let fe: any = this.getFEById(params.selExecId, params.fes);
    let ip: any = {
      assign_params: {
        barcodes: [params.barcode]
      },
      fe_id: fe.user.user_id
    };
    this.shipmentService.assignFE(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "FE Assigned successfully");
      this.jq.closeModal(this.assignSLDFEModalName);
      this.openErrorDisplayModal('de_assigned', response);
      this.dataService.shipmentListPageSelected({offset: this.offset, count: CONSTANTS.ITEM_PER_PAGE});
    });
  }

  moveToOFD(shipment: any) {
    let ip: any = {
      barcodes: [shipment.details.bar_code]
    };
    this.shipmentService.ofd(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "Shipment Moved to OFD successfully");
      this.openErrorDisplayModal('move_to_ofd', response);
      this.dataService.shipmentListPageSelected({offset: this.offset, count: CONSTANTS.ITEM_PER_PAGE});
    });
  }

  downloadPOD(shipment: any) {
    let input = {
      "shipment_id": shipment.id,
      "name": "pod",
    };
    this.shipmentService.downloadData(input).subscribe((response: any) => {
      response.text().then((r: any) => {
        let error;
        try {
          let json = JSON.parse(r);
          if (json.response_code) {
            this.ns.showAlert(CONSTANTS.ERROR, json.errors[0].data);
          } else {
            error = new Error();
          }
        } catch (e) {
          error = e;
        }
        if (error) {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(response);
          a.download = 'pod.zip';
          a.click();
          this.ns.showAlert(CONSTANTS.SUCCESS, "Downloaded successfully");
        }
      });
    });
  }

  downloadSign(shipment: any) {
    let input = {
      "shipment_id": shipment.id,
      "name": "sign",
    };
    this.shipmentService.downloadData(input).subscribe((response: any) => {
      response.text().then((r: any) => {
        let error;
        try {
          let json = JSON.parse(r);
          if (json.response_code) {
            this.ns.showAlert(CONSTANTS.ERROR, json.errors[0].data);
          } else {
            error = new Error();
          }
        } catch (e) {
          error = e;
        }
        if (error) {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(response);
          a.download = 'sign.zip';
          // start download
          a.click();
          this.ns.showAlert(CONSTANTS.SUCCESS, "Downloaded successfully");
        }
      });
    });
  }

  onPageSelected(currentPage: any) {
    this.offset = currentPage * 20;
    this.dataService.shipmentListPageSelected({offset: this.offset, count: CONSTANTS.ITEM_PER_PAGE});
  }

  show_ofd(shipment: any) {
    let user_allowed = [CONSTANTS.USER_TYPES.NETWORK_PARTNER].indexOf(this.sess_acc.user_type) > -1;
    return user_allowed && (shipment.created_for?.details?.locality == shipment.details?.delivery_city) &&
    (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.AT_HUB);
  }

  show_assign(shipment: any) {
    let user_allowed = [CONSTANTS.USER_TYPES.ADMIN, CONSTANTS.USER_TYPES.NETWORK_PARTNER, CONSTANTS.USER_TYPES.NETWORK_PARTNER_SUB_ADMIN].indexOf(this.sess_acc.user_type) > -1;
    return user_allowed && ((shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.OUT_FOR_DELIVERY) || 
    (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.PENDING) || 
    (
      (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.INTRANSIT) &&
      (shipment.details?.pickup_city.toLowerCase() == shipment.details?.delivery_city.toLowerCase())
    ));
  }

  show_unassign(shipment: any) {
    // return ((shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.OUT_FOR_DELIVERY) &&
    // shipment.details.delivery_executive_details?.user_id) || 
    // (
    //   (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.INTRANSIT) &&
    //   (shipment.created_for?.details?.locality == shipment.details?.city) &&
    //   shipment.details.delivery_executive_details?.user_id
    // );
    return false;
  }

  show_location(shipment: any) {
    return (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.DELIVERED) ||
    (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.REJECTED);
  }

  has_pod(shipment: any, pod_type: any) {
    if (pod_type == 'pod') {
      return (shipment.details.delivered?.pod_fn) && (shipment.details.delivered?.pod_fn != null);
    } else {
      return (shipment.details.delivered?.sign_fn) && (shipment.details.delivered?.sign_fn != null);
    }
  }

  get_location(shipment: any) {
    let status_obj: any = {};
    if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.DELIVERED) {
      status_obj = shipment.details?.delivered;
    } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.REJECTED) {
      status_obj = shipment.details?.rejected;
    }
    return status_obj?.location;
  }

  assign_class(shipment: any) {
    if (shipment.details.delivery_executive_details?.user_id) {
      return { 'text-success': 1 };
    }
    return { 'text-success': 0 };
  }

  get_state_time(shipment: any) {
    let dtt: any = '';
    if (this.shipmentType == CONSTANTS.SHIPMENT_TAB.BOOKING) {
      if (this.tab == CONSTANTS.SHIPMENT_TAB.OPTIONS.pickup_executive_assigned) {
        dtt = shipment.details?.pickup_executive_assigned?.dtt;
      } else if (this.tab == CONSTANTS.SHIPMENT_TAB.OPTIONS.picked_from_warehouse) {
        dtt = shipment.details?.picked_from_warehouse?.dtt;
      } else if (this.tab == CONSTANTS.SHIPMENT_TAB.OPTIONS.arrived_at_hub) {
        dtt = shipment.details?.arrived_at_hub?.dtt;
      } else if (this.tab == CONSTANTS.SHIPMENT_TAB.OPTIONS.arrived_at_destination_hub) {
        dtt = shipment.details?.arrived_at_destination_hub?.dtt;
      }
    } else if (this.shipmentType == CONSTANTS.SHIPMENT_TAB.INTRANSIT) {
      dtt = shipment.details?.intransit?.dtt;
    } else if (this.shipmentType == CONSTANTS.SHIPMENT_TAB.OUT_FOR_DELIVERY) {
      dtt = shipment.details?.out_for_delivery?.dtt;
    } else if (this.shipmentType == CONSTANTS.SHIPMENT_TAB.DELIVERED) {
      dtt = shipment.details?.delivered?.dtt;
    } else if (this.shipmentType == CONSTANTS.SHIPMENT_TAB.REJECTED) {
      dtt = shipment.details?.rejected?.dtt;
    } else if (this.shipmentType == CONSTANTS.SHIPMENT_TAB.PENDING) {
      dtt = shipment.details?.pending?.dtt;
    } else if (this.shipmentType == CONSTANTS.SHIPMENT_TAB.CANCELLED) {
      dtt = shipment.details?.cancel?.dtt;
    }
    return dtt;
  }

  get_time_header() {
    let header: any = '';
    if (this.shipmentType == CONSTANTS.SHIPMENT_TAB.BOOKING) {
      if (this.tab == CONSTANTS.SHIPMENT_TAB.OPTIONS.pickup_executive_assigned) {
        header = 'Assigned Time';
      } else if (this.tab == CONSTANTS.SHIPMENT_TAB.OPTIONS.picked_from_warehouse) {
        header = 'Picked Time';
      } else if (this.tab == CONSTANTS.SHIPMENT_TAB.OPTIONS.arrived_at_hub) {
        header = 'Arrived Time';
      } else if (this.tab == CONSTANTS.SHIPMENT_TAB.OPTIONS.arrived_at_destination_hub) {
        header = 'Arrived Time';
      }
    } else if (this.shipmentType == CONSTANTS.SHIPMENT_TAB.INTRANSIT) {
      header = 'Transit Time';
    } else if (this.shipmentType == CONSTANTS.SHIPMENT_TAB.OUT_FOR_DELIVERY) {
      header = 'Out Time';
    } else if (this.shipmentType == CONSTANTS.SHIPMENT_TAB.DELIVERED) {
      header = 'Delivered Time';
    } else if (this.shipmentType == CONSTANTS.SHIPMENT_TAB.REJECTED) {
      header = 'Rejected Time';
    } else if (this.shipmentType == CONSTANTS.SHIPMENT_TAB.PENDING) {
      header = 'Pending Time';
    } else if (this.shipmentType == CONSTANTS.SHIPMENT_TAB.CANCELLED) {
      header = 'Cancelled Time';
    }
    return header;
  }

  get_fe_details(shipment: any, key: any) {
    if (key == 'full_name') {
      if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.PE_ASSIGNED) {
        return shipment.details.pickup_executive_details?.full_name;
      } else {
        return shipment.details.delivery_executive_details?.full_name;
      }
    } else if (key == 'phone') {
      if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.PE_ASSIGNED) {
        return shipment.details.pickup_executive_details?.phone;
      } else {
        return shipment.details.delivery_executive_details?.phone;
      }
    }
  }

  get_np_details(shipment: any, key: any) {
    if (key == 'full_name') {
      if (this.tableParams.show_np) {
        if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.OUT_FOR_DELIVERY) {
          return shipment.details.out_for_delivery?.np?.user?.full_name;
        } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.PE_ASSIGNED) {
          return shipment.details.pickup_executive_assigned?.np?.user?.full_name;
        } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.PICKED_FROM_WAREHOUSE) {
          return shipment.details.picked_from_warehouse?.np?.user?.full_name;
        } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.AT_HUB) {
          return shipment.details.arrived_at_hub?.np?.user?.full_name;
        } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.AT_DHUB) {
          return shipment.details.arrived_at_destination_hub?.np?.user?.full_name;
        } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.DELIVERED) {
          return shipment.details.delivered?.np?.user?.full_name;
        } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.PENDING) {
          return shipment.details.pending?.np?.user?.full_name;
        } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.REJECTED) {
          return shipment.details.rejected?.np?.user?.full_name;
        } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.INTRANSIT) {
          return shipment.details.intransit?.np?.user?.full_name;
        }
      }
    } else if (key == 'phone') {
      if (this.tableParams.show_np) {
        if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.OUT_FOR_DELIVERY) {
          return shipment.details.out_for_delivery?.np?.user?.phone;
        } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.PE_ASSIGNED) {
          return shipment.details.pickup_executive_assigned?.np?.user?.phone;
        } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.PICKED_FROM_WAREHOUSE) {
          return shipment.details.picked_from_warehouse?.np?.user?.phone;
        } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.AT_HUB) {
          return shipment.details.arrived_at_hub?.np?.user?.phone;
        } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.AT_DHUB) {
          return shipment.details.arrived_at_destination_hub?.np?.user?.phone;
        } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.DELIVERED) {
          return shipment.details.delivered?.np?.user?.phone;
        } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.PENDING) {
          return shipment.details.pending?.np?.user?.phone;
        } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.REJECTED) {
          return shipment.details.rejected?.np?.user?.phone;
        } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.INTRANSIT) {
          return shipment.details.intransit?.np?.user?.phone;
        }
      }
    }
  }

  get_count_by_state(shipment: any) {
    if (this.tableParams.show_scanned) {
      if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.PE_ASSIGNED) {
        return shipment.details.picked_from_warehouse?.scanned_count || 0
      } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.PICKED_FROM_WAREHOUSE) {
        return shipment.details.arrived_at_hub?.scanned_count || 0
      } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.AT_HUB) {
        return shipment.details.intransit?.scanned_count || shipment.details.out_for_delivery?.scanned_count || 0
      } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.INTRANSIT) {
        return shipment.details.arrived_at_destination_hub?.scanned_count || 0
      } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.AT_DHUB) {
        return shipment.details.out_for_delivery?.scanned_count || 0
      }
    }
    return 0;
  }

  calculateTableParams(shipment_type: any) {
    let assign_fe: any = [''].indexOf(shipment_type) > -1;
    let cancel: any = [CONSTANTS.SHIPMENT_TYPE.PE_ASSIGNED].indexOf(shipment_type) > -1;
    cancel = cancel && ([CONSTANTS.USER_TYPES.ADMIN, CONSTANTS.USER_TYPES.CLIENT].indexOf(this.sess_acc.user_type) > -1)
    let barcode: any = true;
    let show_state: any = [CONSTANTS.SHIPMENT_TYPE.DELIVERED, CONSTANTS.SHIPMENT_TYPE.REJECTED].indexOf(shipment_type) == -1;
    let show_fe: any = [CONSTANTS.SHIPMENT_TYPE.PE_ASSIGNED, CONSTANTS.SHIPMENT_TYPE.OUT_FOR_DELIVERY,
      CONSTANTS.SHIPMENT_TYPE.DELIVERED, CONSTANTS.SHIPMENT_TYPE.REJECTED, CONSTANTS.SHIPMENT_TYPE.PENDING].indexOf(shipment_type) > -1;
    let show_np: any = [CONSTANTS.SHIPMENT_TYPE.PE_ASSIGNED, CONSTANTS.SHIPMENT_TYPE.PICKED_FROM_WAREHOUSE, CONSTANTS.SHIPMENT_TYPE.AT_HUB, CONSTANTS.SHIPMENT_TYPE.AT_DHUB, CONSTANTS.SHIPMENT_TYPE.INTRANSIT,
        CONSTANTS.SHIPMENT_TYPE.OUT_FOR_DELIVERY, CONSTANTS.SHIPMENT_TYPE.DELIVERED, CONSTANTS.SHIPMENT_TYPE.REJECTED, CONSTANTS.SHIPMENT_TYPE.PENDING].indexOf(shipment_type) > -1;
    show_np = show_np && (this.sess_acc.user_type == 'admin');
    let show_otp = [CONSTANTS.SHIPMENT_TYPE.OUT_FOR_DELIVERY].indexOf(shipment_type) > -1;
    show_otp = show_otp && ([CONSTANTS.USER_TYPES.ADMIN].indexOf(this.sess_acc.user_type) > -1);
    let show_cd: any = [CONSTANTS.SHIPMENT_TYPE.INTRANSIT].indexOf(shipment_type) > -1;
    let show_scanin: any = [CONSTANTS.SHIPMENT_TYPE.PICKED_FROM_WAREHOUSE].indexOf(shipment_type) > -1;
    show_scanin = show_scanin && ([CONSTANTS.USER_TYPES.ADMIN, CONSTANTS.USER_TYPES.NETWORK_PARTNER, CONSTANTS.USER_TYPES.NETWORK_PARTNER_SUB_ADMIN].indexOf(this.sess_acc.user_type) > -1)
    let show_scanout: any = (CONSTANTS.SHIPMENT_TYPE.AT_HUB == shipment_type);
    show_scanout = show_scanout && ([CONSTANTS.USER_TYPES.ADMIN, CONSTANTS.USER_TYPES.NETWORK_PARTNER, CONSTANTS.USER_TYPES.NETWORK_PARTNER_SUB_ADMIN].indexOf(this.sess_acc.user_type) > -1)
    if (!show_scanout) {
      show_scanout = (CONSTANTS.SHIPMENT_TYPE.AT_DHUB == shipment_type);
      show_scanout = show_scanout && ([CONSTANTS.USER_TYPES.NETWORK_PARTNER].indexOf(this.sess_acc.user_type) > -1)
    }
    let show_scanned: any = [CONSTANTS.SHIPMENT_TYPE.PE_ASSIGNED, CONSTANTS.SHIPMENT_TYPE.PICKED_FROM_WAREHOUSE, CONSTANTS.SHIPMENT_TYPE.AT_HUB, CONSTANTS.SHIPMENT_TYPE.INTRANSIT, CONSTANTS.SHIPMENT_TYPE.AT_DHUB].indexOf(shipment_type) > -1;
    let show_remarks: any = [CONSTANTS.SHIPMENT_TYPE.REJECTED].indexOf(shipment_type) > -1;
    let show_pod: any = [CONSTANTS.SHIPMENT_TYPE.DELIVERED].indexOf(shipment_type) > -1;
    let show_multi_selection : any = (CONSTANTS.SHIPMENT_TYPE.PICKED_FROM_WAREHOUSE == shipment_type);
    show_multi_selection = show_multi_selection && ([CONSTANTS.USER_TYPES.ADMIN, CONSTANTS.USER_TYPES.NETWORK_PARTNER].indexOf(this.sess_acc.user_type) > -1);
    let tableParams: any = {
      has_actions: assign_fe || barcode || cancel || show_cd || show_np || show_fe || show_scanin || show_scanout || show_otp,
      assign_fe: assign_fe,
      barcode: barcode,
      cancel: cancel,
      show_cd: show_cd,
      show_np: show_np,
      show_fe: show_fe,
      show_otp: show_otp,
      show_scanned: show_scanned,
      show_scanin: show_scanin,
      show_scanout: show_scanout,
      show_state: show_state,
      show_remarks: show_remarks,
      show_pod: show_pod,
      show_multi_selection: show_multi_selection
    };
    this.tableParams = tableParams;
  }

  getFEByNp(np_id: any, fe_type: any, cb: any) {
    let input = { user_type: "np_fe", executive_type: fe_type, np_id: np_id };
    this.accountService.getAccounts(input).subscribe((response: any) => {
      if (cb) {
        cb(response);
      }
    });
  }

  getNpByLocality(locality: any, cb: any) {
    let input = { user_type: "np", locality: locality };
    this.accountService.getAccounts(input).subscribe((response: any) => {
      if (cb) {
        cb(response);
      }
    });
  }

  onShipmentSelected(manifest: any, event: any) {
    manifest.selected = event.target.checked;
    let all_selected: any = true, any_selected: any = false;
    for (let idx = 0; idx < this.shipments.length; idx++) {
      all_selected = all_selected && !!this.shipments[idx].selected;
      any_selected = any_selected || !!this.shipments[idx].selected;
    }
    this.all_selected = all_selected;
    this.any_selected = any_selected;
  }

  onAllShipmentsSelected(event: any) {
    this.all_selected = event.target.checked;
    for (let idx = 0; idx < this.shipments.length; idx++) {
      this.shipments[idx].selected = this.all_selected;
    }
    this.any_selected = this.all_selected;
  }

  openAssignSLFEModal(shipment: any) {
    this.getNpByLocality(shipment.details?.city, (response: any) => {
      if (!response.accounts || (response.accounts.length == 0)) {
        this.ns.showAlert(CONSTANTS.ERROR, 'No Network Partners available for the locality. Please add the Network Partner and FE before assigning.');
        return;
      }
      this.assignParams = {
        nps: response.accounts,
        order_id: shipment.details.order_no,
        barcode: shipment.details.bar_code,
        selNpId: response.accounts[0].user.user_id,
        selExecId: shipment.details.pickup_executive_details?.user_id
      };
      let np: any = this.getNPById(this.assignParams.selNpId, this.assignParams.nps);
      if (np) {
        this.getFEByNp(np.account_id, 'pickup_executive', (response: any) => {
          this.assignParams.fes = response.accounts;
          if (!this.assignParams.selExecId) {
            this.assignParams.selExecId = response.accounts[0].user.user_id;
          }
          this.jq.openModal('assignSLFEModal');
        });
      } else {
        this.ns.showAlert(CONSTANTS.ERROR, 'No Network Partners available for the locality. Please add the Network Partner and FE before assigning.');
      }
    });
  }

  openConfirmationModal(shipment: any, from: any) {
    this.confirmationParams = {
      from: from,
      order_id: shipment.details.order_no,
      barcode: shipment.details.bar_code,
      obj: shipment,
      header: 'Confirmation',
      msg: (from == 'cancel_shipment') ? 'Are you sure want to Cancel the shipment?' : 
      (from == 'scan_out') ? 'Are you sure want to scan out the shipment?' : 'Are you sure want to Unassign the executive?'
    };
    this.jq.openModal(this.confirmModalName);
  }

  showBarcodes(shipment: any) {
    let barcodes: any = [];
    for (let iidx = 0; iidx < shipment.details.total_box; iidx++) {
      barcodes.push({
        index: iidx,
        barcode: shipment.details.bar_code,
        box_no: iidx + 1,
        obj: shipment,
        label: `Slide ${iidx}`
      });
    }
    this.viewBarcodeParams = {
      barcodes: barcodes
    }
    this.jq.openModal(this.slBarcodeModalName);
  }

  openColoaderModal(shipment: any) {
    this.coloaderParams = {
      barcode: shipment.details.bar_code,
      name: shipment.coloader?.name,
      phone: shipment.coloader?.phone,
      city: shipment.coloader?.city
    };
    this.jq.openModal(this.coloaderModalName);
  }

  openScanoutModal(shipment: any) {
    if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.AT_HUB) {
      this.getNpByLocality(shipment.details.delivery_city, (response: any) => {
        if (response.accounts && response.accounts.length > 0) {
          this.scanoutParams = {
            shipment: shipment,
            type: 'np',
            nps: response.accounts,
            np_id: response.accounts[0].user.user_id
          };
          this.jq.openModal('scanoutModal');
        } else {
          this.ns.showAlert(CONSTANTS.ERROR, 'No Network Partners Found for the Delivery location.');
        }
      });
    } else if (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.AT_DHUB) {
      this.openConfirmationModal(shipment, 'scan_out');
    }
  }

  openScaninModal(shipment: any) {
    this.scaninShipments([shipment]);
  }

  scaninShipments(shipments: any) {
    let barcodes: any = [];
    for (let idx = 0; idx < shipments.length; idx++) {
      barcodes.push(shipments[idx].details.bar_code);
    }
    if (barcodes.length == 0) {
      this.ns.showAlert(CONSTANTS.ERROR, 'No Barcodes to scanin');
      return;
    }
    let ip: any = {
      barcodes: barcodes,
      scanin_type: 'arrived_at_hub',
      force: 1
    };
    this.shipmentService.scanin(ip).subscribe((response: any) => {
      this.all_selected = false;
      this.any_selected = false;
      this.openErrorDisplayModal('scanin', response);
      this.dataService.shipmentListPageSelected({offset: this.offset, count: CONSTANTS.ITEM_PER_PAGE});
    });
  }

  openAssignSLDFEModal(shipment: any) {
    this.assignParams = {
      order_id: shipment.details.order_no,
      barcode: shipment.details.bar_code,
      selExecId: shipment.details.delivery_executive_details?.user_id
    };
    let np_id: any;
    if (shipment.details.delivery_city.toLowerCase() == shipment.details.pickup_city.toLowerCase()) {
      np_id = shipment.details.out_for_delivery?.np?.account_id;
    } else {
      np_id = shipment.details.intransit?.np?.account_id;
    }
    this.getFEByNp(np_id, 'delivery_executive', (response: any) => {
      if (response.accounts.length > 0) {
        this.assignParams.fes = response.accounts;
        if (!this.assignParams.selExecId) {
          this.assignParams.selExecId = response.accounts[0].user.user_id;
        }
        this.jq.openModal(this.assignSLDFEModalName);
      } else {
        this.ns.showAlert(CONSTANTS.ERROR, "No FE available for the Network Partner.");
      }
    });
  }

  openErrorDisplayModal(from: any, response: any) {
    let errors: any = [];
    for (let idx = 0; idx < response.shipments.length; idx++) {
      errors.push({
        barcode: response.shipments[idx].details.bar_code,
        msg: '',
        success: true
      });
    }
    for (let barcode in response.errors) {
      errors.push({
        barcode: barcode,
        msg: response.errors[barcode] || '',
        success: false
      });
    }
    let header: any = '';
    if (from == 'de_assigned') {
      header = 'Assignment Status';
    } else if (from == 'move_to_ofd') {
      header = 'Move to OFD Status';
    } else if (from == 'scanin') {
      header = 'Scan In';
    } else if (from == 'scanout') {
      header = 'Scan Out';
    }
    this.errDisplayParams = {
      header: header,
      errors: errors
    };
    this.jq.openModal(this.errorDisplayModalName);
  }

  closeAssignSLFEModal() {
    this.assignParams = { };
    this.jq.closeModal('assignSLFEModal');
  }

  closeConfirmModal(params: any) {
    this.confirmationParams = { };
    this.jq.closeModal(this.confirmModalName);
  }

  closeBarcodeModal() {
    this.viewBarcodeParams = { barcodes: [] };
    this.jq.closeModal(this.slBarcodeModalName);
  }

  closeColoaderModal() {
    this.coloaderParams = { };
    this.jq.closeModal(this.coloaderModalName);
  }

  closeScanoutModal() {
    this.jq.closeModal('scanoutModal');
  }

  closeAssignSLDFEModal() {
    this.assignParams = { };
    this.jq.closeModal(this.assignSLDFEModalName);
  }

  closeErrorDisplayModal() {
    this.errDisplayParams = { };
    this.jq.openModal(this.errorDisplayModalName);
  }

  onNPSelected(params: any, action: any) {
    if (action == 'assign_fe') {
      this.getFEByNp(params.selNpId, 'pickup_executive', (response: any) => {
        params.fes = response.accounts;
        params.selExecId = response.accounts[0].account_id;
      });
    }
  }

  onFESelected(params: any, action: any) {
    if (action == 'assign_fe') {
    } else if (action == 'assign_de') {
    } else if (action == 'scan_out') {
    }
  }

  getFEById(fe_id: any, fes: any = []) {
    let fe: any = null;
    for (let idx = 0; idx < fes.length; idx++) {
      if ((fes[idx].account_id != "") && (fes[idx].user.user_id == fe_id)) {
        fe = fes[idx];
        break;
      }
    }
    return fe;
  }

  getNPById(np_id: any, nps: any = []) {
    let np: any = null;
    for (let idx = 0; idx < nps.length; idx++) {
      if ((nps[idx].account_id != "") && (nps[idx].user.user_id == np_id)) {
        np = nps[idx];
        break;
      }
    }
    return np;
  }

  calculateSelectedShipments() {
    let ships: any = [];
    for (let idx = 0; idx < this.shipments.length; idx++) {
      if (!!this.shipments[idx].selected) {
        ships.push(this.shipments[idx]);
      }
    }
    return ships;
  }
}
