import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { CONSTANTS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService extends ApiService {

  public getShipments(data: any) {
    return this.getQueryParams(CONSTANTS.API.SHIPMENT.GET_SHIPMENTS, data);
  }
  public getRPShipments(data: any) {
    return this.getQueryParams(CONSTANTS.API.SHIPMENT.GET_RP_SHIPMENTS, data);
  }
  public getUploadShipments(data: any) {
    return this.getQueryParams(CONSTANTS.API.SHIPMENT.GET_SUD_SHIPMENTS, data);
  }
  public getInvoices(data: any) {
    return this.getQueryParams(CONSTANTS.API.SHIPMENT.GET_INVOICE_DETAILS, data);
  }
  public createShipment(data: any) {
    return this.post(CONSTANTS.API.SHIPMENT.CREATE_SHIPMENT, data);
  }
  public verifyXlsFile(data: any) {
    return this.post(CONSTANTS.API.SHIPMENT.VERIFY_UPLOAD_XLS, data);
  }
  public assignShipment(data: any) {
    return this.post(CONSTANTS.API.SHIPMENT.ASSIGN_SHIPMENT, data);
  }
  public assignRPShipment(data: any) {
    return this.put(CONSTANTS.API.SHIPMENT.ASSIGN_RP_SHIPMENT, data);
  }
  public deliverRPShipment(data: any) {
    return this.put(CONSTANTS.API.SHIPMENT.DELIVER_RP_SHIPMENT, data);
  }
  public cancelShipment(data: any) {
    return this.post(CONSTANTS.API.SHIPMENT.CANCEL_SHIPMENT, data);
  }
  public saveColoaderDetails(data: any) {
    return this.post(CONSTANTS.API.SHIPMENT.SAVE_COLOADER_DETAILS, data);
  }
  public assignFE(data: any) {
    return this.post(CONSTANTS.API.SHIPMENT.ASSIGN_FE, data);
  }
  public unassignFE(data: any) {
    return this.deleteWithBody(CONSTANTS.API.SHIPMENT.UNASSIGN_FE, data);
  }
  public getBarcodes(data: any) {
    return this.getQueryParams(CONSTANTS.API.SHIPMENT.GET_BARCODES, data);
  }
  public dashboard(data: any) {
    return this.getQueryParams(CONSTANTS.API.SHIPMENT.DASHBOARD, data);
  }
  public uploadHistory(data: any) {
    return this.getQueryParams(CONSTANTS.API.SHIPMENT.SUDS, data);
  }
  public processUpload(data: any) {
    return this.post(CONSTANTS.API.SHIPMENT.PROCESS_SUD, data);
  }
  public search(data: any) {
    return this.getQueryParams(CONSTANTS.API.SHIPMENT.SEARCH, data);
  }
  public downloadData(data: any) {
    return this.download(CONSTANTS.API.SHIPMENT.DOWNLOAD, data);
  }
  public scanin(data: any) {
    return this.post(CONSTANTS.API.SHIPMENT.SCAN_IN, data);
  }
  public scanout(data: any) {
    return this.post(CONSTANTS.API.SHIPMENT.SCAN_OUT, data);
  }
  public ofd(data: any) {
    return this.put(CONSTANTS.API.SHIPMENT.OFD, data);
  }
  public getManifestCities(data: any) {
    return this.getQueryParams(CONSTANTS.API.SHIPMENT.GET_MANIFEST_CITIES, data);
  }
  public getManifestNps(data: any) {
    return this.getQueryParams(CONSTANTS.API.SHIPMENT.GET_MANIFEST_NPS, data);
  }
  public getRPManifestFEs(data: any) {
    return this.getQueryParams(CONSTANTS.API.SHIPMENT.GET_RP_MANIFEST_FES, data);
  }
  public getManifests(data: any) {
    return this.getQueryParams(CONSTANTS.API.SHIPMENT.GET_MANIFEST, data);
  }
  public getRPManifests(data: any) {
    return this.getQueryParams(CONSTANTS.API.SHIPMENT.GET_RP_MANIFEST, data);
  }
  public getPickupManifests(data: any) {
    return this.getQueryParams(CONSTANTS.API.SHIPMENT.GET_PICKUP_MANIFEST, data);
  }
  public generatePickupManifest(data: any) {
    return this.post(CONSTANTS.API.SHIPMENT.GENERATE_PICKUP_MANIFEST, data);
  }
  public generateManifest(data: any) {
    return this.post(CONSTANTS.API.SHIPMENT.GENERATE_MANIFEST, data);
  }
  public generateRPManifest(data: any) {
    return this.put(CONSTANTS.API.SHIPMENT.GENERATE_RP_MANIFEST, data);
  }
  public getManifestShipments(data: any) {
    return this.getQueryParams(CONSTANTS.API.SHIPMENT.GET_MANIFEST_SHIPMENTS, data);
  }
  public getRPManifestShipments(data: any) {
    return this.getQueryParams(CONSTANTS.API.SHIPMENT.GET_RP_MANIFEST_SHIPMENTS, data);
  }
  public assignManifestNP(data: any) {
    return this.put(CONSTANTS.API.SHIPMENT.ASSIGN_MANIFEST_NP, data);
  }
  public mergeManifests(data: any) {
    return this.put(CONSTANTS.API.SHIPMENT.MERGE_MANIFESTS, data);
  }
  public downloadManifest(data: any) {
    return this.download(CONSTANTS.API.SHIPMENT.DOWNLOAD_MANIFEST, data);
  }
  public downloadRPManifest(data: any) {
    return this.download(CONSTANTS.API.SHIPMENT.DOWNLOAD_RP_MANIFEST, data);
  }
  public downloadPickupManifest(data: any) {
    return this.download(CONSTANTS.API.SHIPMENT.DOWNLOAD_PICKUP_MANIFEST, data);
  }
  public downloadUploadedXls(data: any) {
    return this.download(CONSTANTS.API.SHIPMENT.GET_UPLOADED_XLS, data);
  }
}
