import { AccountService } from '../../services/api/account.service';
import { ShipmentService } from '../../services/api/shipment.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '../../constants';
import { NotificationService } from '../../services/notification.service';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utility.service';
import { JqueryUtils } from '../../utils/jquery.utils';
import { StateService } from '../../services/api/state.service';
import { DistrictService } from '../../services/api/district.service';
import { PrintService } from '../../services/api/print.service';

@Component({
  selector: 'app-createshipment',
  templateUrl: './createshipment.component.html',
  styleUrls: ['./createshipment.component.scss']
})

export class CreateShipmentComponent implements OnInit, OnDestroy {

  sess_acc: any;
  offset = 0;
  count = 20;
  show_client_selection = true;
  show_upload = true;
  totalItems = 0;
  suds: any = [];
  shipments: any = [];
  barcodes: any = [];
  is_uploading: any = false;
  docData: any = {
    isUploaded: false
  };
  tableParams: any = {
    assign_pfe: false
  };
  assignFEParams: any = {
    nps: [],
    selNpId: '',
    fes: [],
    selExecId: ''
  };
  fileName: any;
  verifyParams: any = { rows: [] };
  fcmNotificationParams: any = { header: '', msg: '' };

  utypes = [
    { full_name: "Select", uid: 0 },
    { full_name: "CFL", tag: 'intracity', uid: 1 },
    { full_name: "South/Upcountry", tag: 'intercity south', uid: 2 },
    // { full_name: "Intercity", uid: 3 }
  ];
  selUploadTypeId = this.utypes[0].uid;
  upload_selection: any = '';

  clients = [{ user: { full_name: "Select", user_id: "" , tag:"" } }];
  selClientId = this.clients[0].user.user_id;

  states = [{ full_name: "Select", _id: 0 , tag:"" }];
  selPickupStateId = this.states[0]._id;
  selDeliveryStateId = this.states[0]._id;

  pickup_districts = [ { full_name: "Select", _id: 0 , tag:"" }];
  selPickupDistrictId = this.pickup_districts[0]._id;

  delivery_districts = [ { full_name: "Select", _id: 0 , tag:"" }];
  selDeliveryDistrictId = this.delivery_districts[0]._id;
  subscriber_id: any = 'create-shipment';

  constructor(private accountService: AccountService, private dataService: DataService,
    private shipmentService: ShipmentService, private stateService: StateService, private ns: NotificationService,
    private printService: PrintService, private utilityService: UtilityService, private districtService: DistrictService,
    private router: Router, private jq: JqueryUtils) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.calculate_params();
    this.getStates(() => {
      this.applyDefaults();
    });
    this.getUploadHistory();
    this.getClients();
    this.dataService.onFcmNotificationReceived(this.subscriber_id).subscribe(res => {
      this.fcmNotificationParams = {
        header: res.data.data_title,
        msg: res.data.data_body
      };
      this.jq.openModal('fcmNotificationModal');
    });
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
    this.dataService.onAPIError(this.subscriber_id).subscribe(res => {
      this.is_uploading = false;
    });
  }

  calculate_params() {
    this.show_client_selection = (this.sess_acc.user_type == 'admin');
    this.show_upload = [CONSTANTS.USER_TYPES.ADMIN, CONSTANTS.USER_TYPES.CLIENT].indexOf(this.sess_acc.user_type) > -1;
  }

  applyDefaults() {
    if (this.sess_acc.user_type == 'admin') return;
    let state: any = this.getStateByTag(this.sess_acc.details.state);
    this.selPickupStateId = state._id;
    this.selDeliveryStateId = state._id;
    this.getDistricts('pickup', true);
    this.getDistricts('delivery', true);
  }

  openAssignFEModal(sud: any) {
    this.getNPs(sud.pickup_city, (nps: any)=> {
      if (!nps || (nps.length == 0)) {
        this.ns.showAlert(CONSTANTS.ERROR, 'No Partners Found for the pickup city.');
        return;
      }
      let np_id: any = nps[0].user.user_id;
      this.assignFEParams = {
        nps: nps,
        selNpId: np_id
      }
      this.getFEs(np_id, (response: any) => {
        this.assignFEParams.fes = response.accounts;
        this.assignFEParams.selExecId = this.assignFEParams.fes[0].account_id;
        this.assignFEParams.upload_id = sud._id;
        this.jq.openModal('assignFEModal');
      });
    });
  }

  closeAssignFEModal() {
    this.assignFEParams = { };
    this.jq.closeModal('assignFEModal');
  }

  closeBarcodeModal() {
    this.shipments = [];
    this.barcodes = [];
    this.jq.closeModal('barcodeModal');
  }

  closeVerifyModal() {
    this.jq.closeModal('verifyModal');
    this.verifyParams = { rows: [] };
  }

  showBarcodes(sud: any) {
    this.getBarcodes(sud, (barcodes: any, shipments: any) => {
      this.barcodes = barcodes;
      this.shipments = shipments;
      if (this.barcodes.length > 0) {
        this.jq.openModal('barcodeModal');
      } else {
        this.ns.showAlert(CONSTANTS.ERROR, 'No Barcodes Found.');
      }
    });
  }

  downloadXls(sud: any) {
    let ip: any = {
      sud_id: sud._id
    };
    this.shipmentService.downloadUploadedXls(ip).subscribe((response: any) => {
      response.text().then((r: any) => {
        let error;
        try {
          let json = JSON.parse(r);
          if (json.response_code) {
            this.ns.showAlert(CONSTANTS.ERROR, json.errors[0].data);
          } else {
            error = new Error();
          }
        } catch (e) {
          error = e;
        }
        if (error) {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(response);
          a.download = sud.file_name;
          // start download
          a.click();
          // this.ns.showAlert(CONSTANTS.SUCCESS, "Downloaded successfully");
        }
      });
    });
  }

  assign_class(sud: any) {
    if (sud.details.fe) {
      return { 'text-success': 1 };
    }
    return { 'text-success': 0 };
  }

  onSelUploadType() {
    this.setValues();
  }

  onSelState(selection: any) {
    this.getDistricts(selection, false);
  }

  onSelClient() {
    this.setValues();
  }

  onSelDistrict(selection: any) { }
  onSelExecutive(params: any) { }

  onSelNp(params: any) {
    this.getFEs(params.selNpId, (response: any) => {
      params.fes = response.accounts;
      if (params.fes.length > 0) {
        params.selExecId = params.fes[0].account_id;
      } else {
        params.selExecId = "";
      }
    });
  }

  setValues() {
    let utype: any = this.getUploadTypeByUid(this.selUploadTypeId);
    if (utype == null) {
      this.selPickupStateId = 0;
      this.selDeliveryStateId = 0;
      this.upload_selection = '';
      this.clearSearch('pickup', 'state')
      this.clearSearch('pickup', 'district')
      this.clearSearch('delivery', 'state')
      this.clearSearch('delivery', 'district')
    } else {
      this.upload_selection = utype.tag.toLowerCase();
      let client: any;
      if (this.show_client_selection) {
        client = this.getClientById(this.selClientId);
      } else {
        client = this.getClientById(this.sess_acc.user.user_id);
      }
      let state: any = this.getStateByTag(client.details.state);
      this.selPickupStateId = state._id;
      this.getDistricts('pickup', true);
      if (utype.tag.toLowerCase() == 'intercity') {
        this.selDeliveryStateId = state._id;
        this.getDistricts('delivery', true);
      } else if (utype.tag.toLowerCase() == 'intercity south') {
        this.selDeliveryStateId = 0;
        this.selDeliveryDistrictId = 0;
      } else {
        this.selDeliveryStateId = 0;
        this.selDeliveryDistrictId = 0;
        this.getDistricts('delivery', false);
      }
    }
  }

  getDistricts(selection: any, select_client_locality: any) {
    let state: any;
    if (selection == 'pickup') {
      state = this.getStateById(this.selPickupStateId);
    } else {
      state = this.getStateById(this.selDeliveryStateId);
    }
    if (state == null) {
      // this.ns.showAlert(CONSTANTS.ERROR, `${(selection == 'pickup') ? 'Pickup' : 'Delivery'} State should be selected`);
      return;
    }
    if (selection == 'pickup') {
      this.pickup_districts = [];
    } else {
      this.delivery_districts = [];
    }
    let input = { state: state.full_name };
    this.districtService.getDistricts(input).subscribe((response: any) => {
      let p = [{ full_name: "Select", _id: 0 , tag:""}];
      response.districts.forEach((element: any, index: any) => {
        let data = { full_name:element, _id:index+1, tag:element };
        p.push(data)
      });
      let client: any, location: any
      if (selection == 'pickup') {
        this.pickup_districts = p;
        if (this.pickup_districts.length > 1) {
          if (select_client_locality) {
            if (this.show_client_selection) {
              client = this.getClientById(this.selClientId);
              location = this.getDistrictByTag(client.details.locality, selection);
            } else {
              location = this.getDistrictByTag(this.sess_acc.details.locality, selection);
            }
            this.selPickupDistrictId = location._id;
          } else {
            this.selPickupDistrictId = this.pickup_districts[1]._id;
          }
        }
      } else {
        this.delivery_districts = p;
        if (this.delivery_districts.length > 1) {
          if (select_client_locality) {
            if (this.show_client_selection) {
              client = this.getClientById(this.selClientId);
              location = this.getDistrictByTag(client.details.locality, selection);
            } else {
              location = this.getDistrictByTag(this.sess_acc.details.locality, selection);
            }
            this.selDeliveryDistrictId = location._id;
          } else {
            this.selDeliveryDistrictId = this.delivery_districts[0]._id;
          }
        }
      }
    });
  }

  getStates(cb: any) {
    this.states = [];
    this.stateService.getStates().subscribe((response: any) => {
      let states: any = [{ full_name: "Select", _id: 0 , tag:"" }];
      response.states.forEach((state: any, idx: any) => {
        states.push({ full_name: state, _id: idx+1, tag: state });
      });
      this.states = states;
      if (cb) {
        cb();
      }
    });
  }

  getBarcodes(sud: any, cb: any) {
    let input: any = {
      upload_id: sud._id
    };
    this.shipmentService.getBarcodes(input).subscribe((response: any) => {
      let shipments: any = response.shipments;
      let barcodes: any = [];
      let shipment: any;
      for (let idx = 0; idx < shipments.length; idx++) {
        shipment = shipments[idx];
        for (let iidx = 0; iidx < shipment.total_boxes; iidx++) {
          barcodes.push({barcode: shipment.bar_code, obj: shipment, box_no: `${iidx+1}/${shipment.total_boxes}` });
        }
      }
      if (cb) {
        cb(barcodes, shipments);
      }
    });
  }

  getUploadHistory() {
    this.is_uploading = true;
    let input = {
      "offset": this.offset,
      "limit": 20
    };
    this.shipmentService.uploadHistory(input).subscribe((response: any) => {
      this.is_uploading = false;
      this.suds = [];
      response.suds.forEach((sud: any) => {
        sud.show_assign = (sud.details.processed == sud.details.total);
        sud.show_assign = sud.show_assign && [CONSTANTS.USER_TYPES.ADMIN].indexOf(this.sess_acc.user_type) > -1
      });
      this.suds = response.suds;
      this.totalItems = response.count;
    });
  }

  getClients() {
    this.clients = [];
    let input = {"user_type": "client"};
    this.accountService.getAccounts(input).subscribe((response: any) => {
      this.clients = response.accounts;
    });
  }

  getNPs(locality: any, cb: any) {
    let input = { "user_type": "np", locality: locality };
    this.accountService.getAccounts(input).subscribe((response: any) => {
      let nps: any = response.accounts;
      if (cb) {
        cb(nps);
      }
    });
  }

  getFEs(npId: any, cb: any) {
    let np: any = this.getNPById(npId, this.assignFEParams.nps);
    let np_id: any = "";
    if (np) {
      np_id = np.account_id;
    }
    let input = { "user_type": "np_fe", np_id: np_id, executive_type: 'pickup_executive' };
    this.accountService.getAccounts(input).subscribe((response: any) => {
      if (cb) {
        cb(response);
      }
    });
  }

  onPageSelected(currentPage: any) {
    this.offset = currentPage * 20;
    this.getUploadHistory();
  }

  docSelect(fileInput: any) {
    this.docData.file = <File>fileInput.target.files[0];
    this.fileName = this.docData.file.name;
    this.docData.isUploaded = true;
    var nextSibling = fileInput.target.nextElementSibling
    nextSibling.innerText = this.fileName
  }

  deleteDocument(docName: any) {
    this.docData.file = null;
    this.fileName = undefined;
    this.docData.isUploaded = false;
  }

  constructFormData() {
    let utype: any = this.getUploadTypeByUid(this.selUploadTypeId);
    if(utype == null) {
        this.ns.showAlert(CONSTANTS.ERROR, "Please select the type of upload");
        return;
    }
    let client: any = null;
    if (this.show_client_selection) {
      client = this.getClientById(this.selClientId);
      if(client == null) {
          this.ns.showAlert(CONSTANTS.ERROR, "Customer not selected");
          return;
      }
    }
    if(this.docData.file == null ||
      this.docData.file == "undefined"){
        this.ns.showAlert(CONSTANTS.ERROR, "File not selected");
        return;
    }
    const formData = new FormData();
    formData.append('file', this.docData.file);
    let pickup_district: any = this.getDistrictById(this.selPickupDistrictId, 'pickup');
    if(pickup_district == null) {
      this.ns.showAlert(CONSTANTS.ERROR, "Pickup City must be selected");
      return;
    }
    let delivery_district: any = this.getDistrictById(this.selDeliveryDistrictId, 'pickup');
    if (utype.tag.toLowerCase() == 'intercity') {
      if(delivery_district == null) {
        this.ns.showAlert(CONSTANTS.ERROR, "Delivery City must be selected");
        return;
      }
    }
    formData.append('origin_city', pickup_district.full_name);
    if (utype.tag.toLowerCase() == 'intracity') {
      formData.append('deliver_city', pickup_district.full_name);
    } else {
      if(delivery_district == null) {
        formData.append('deliver_city', '');
      } else {
        formData.append('deliver_city', delivery_district.full_name);
      }
    }
    if (this.show_client_selection) {
      formData.append('created_for', client.user.user_id);
    }
    formData.append('xls_type', "courier_titan");
    return formData;
  }

  verifyFile() {
    let formData: any = this.constructFormData();
    if (!formData) return;
    this.shipmentService.verifyXlsFile(formData).subscribe((response: any) => {
      let rows: any = [{awb: response.shipment_count, box: response.box_count,
        pickup_city: response.pickup_city, deliver_city: response.deliver_city, intracity: response.intracity,
        duplicate_count: response.duplicate_order_ids?.length || 0 }];
      let drows: any = [], row, keys;
      for (let idx = 0; idx < response.duplicate_order_ids.length; idx++) {
        if (drows.length == 0) {
          row = { id1 : response.duplicate_order_ids[idx] };
          drows.push(row);
        } else {
          row = drows[drows.length - 1];
          keys = Object.keys(row);
          if (keys.length >= 6) {
            row = { id1 : response.duplicate_order_ids[idx] };
            drows.push(row);
          } else {
            row[`id${keys.length+1}`] = response.duplicate_order_ids[idx];
          }
        }
      }
      this.verifyParams = {
        rows: rows,
        drows: drows
      };
      this.jq.openModal('verifyModal');
    });
  }

  uploadFile(name: any) {
    this.is_uploading = true;
    let formData: any = this.constructFormData();
    if (!formData) {
      this.is_uploading = false;
      return;
    }
    this.shipmentService.createShipment(formData).subscribe((response: any) => {
      this.docData.isUploaded = true;
      this.is_uploading = false;
      this.ns.showAlert(CONSTANTS.SUCCESS, "uploaded successfully");
      this.getUploadHistory();
      if (name == 'verify') {
        this.jq.closeModal('verifyModal');
      }
    });
  }

  assignExecutive(params: any) {

    let fe: any = this.getFEById(params.selExecId, params.fes);
    let ip: any = {
      assign_params: {
        upload_id: params.upload_id
      },
      fe_id: fe.user.user_id
    };
    this.shipmentService.assignShipment(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "FE Assigned successfully");
      this.getUploadHistory();
      this.jq.closeModal('assignFEModal');
    });
  }

  printBarcodes(sud: any) {
    this.getBarcodes(sud, (barcodes: any, shipments: any) => {
      let ip: any = { codes: barcodes };
      this.printService.printBarcodes(ip).subscribe((response: any) => {

      });
    });
  }

  viewShipments(sud: any) {
    let root: any = (this.sess_acc.user_type == 'admin') ? `/${CONSTANTS.ROUTES.ROOT.ADMIN}` : `/${CONSTANTS.ROUTES.ROOT.USER}`;
    this.router.navigate([root, CONSTANTS.ROUTES.UPLOAD_SHIPMENTS], { queryParams: {upload_id: sud._id}});
  }

  clearSearch(selection: any, clear_on: any) {
    if (selection == 'delivery') {
      if (clear_on == 'state') {
        this.selDeliveryStateId = this.states[0]._id;
      }
      this.selDeliveryDistrictId = this.delivery_districts[0]._id;
    } else if (selection == 'pickup') {
      if (clear_on == 'state') {
        this.selPickupStateId = this.states[0]._id;
      }
      this.selPickupDistrictId = this.pickup_districts[0]._id;
    }
  }

  getUploadTypeByUid(uid: any) {
    let utype: any = null;
    for (let idx = 0; idx < this.utypes.length; idx++) {
      if ((this.utypes[idx].uid != 0) && (this.utypes[idx].uid == uid)) {
        utype = this.utypes[idx];
        break;
      }
    }
    return utype;
  }

  getStateById(state_id: any) {
    let state: any = null;
    for (let idx = 0; idx < this.states.length; idx++) {
      if ((this.states[idx]._id != 0) && (this.states[idx]._id == state_id)) {
        state = this.states[idx];
        break;
      }
    }
    return state;
  }

  getStateByTag(tag: any) {
    let state: any = null;
    for (let idx = 0; idx < this.states.length; idx++) {
      if ((this.states[idx]._id != 0) && (this.states[idx].tag.toLowerCase() == tag.toLowerCase())) {
        state = this.states[idx];
        break;
      }
    }
    return state;
  }

  getDistrictByTag(tag: any, selection: any) {
    let districts: any = [];
    if (selection == 'pickup') {
      districts = this.pickup_districts;
    } else {
      districts = this.delivery_districts;
    }
    let district: any = null;
    for (let idx = 0; idx < districts.length; idx++) {
      if ((districts[idx]._id != 0) && (districts[idx].tag.toLowerCase() == tag.toLowerCase())) {
        district = districts[idx];
        break;
      }
    }
    return district;
  }

  getDistrictById(district_id: any, selection: any) {
    let districts: any = [];
    if (selection == 'pickup') {
      districts = this.pickup_districts;
    } else {
      districts = this.delivery_districts;
    }
    let district: any = null;
    for (let idx = 0; idx < districts.length; idx++) {
      if ((districts[idx]._id != 0) && (districts[idx]._id == district_id)) {
        district = districts[idx];
        break;
      }
    }
    return district;
  }

  getClientById(client_id: any) {
    let client: any = null;
    for (let idx = 0; idx < this.clients.length; idx++) {
      if ((this.clients[idx].user.user_id != "") && (this.clients[idx].user.user_id == client_id)) {
        client = this.clients[idx];
        break;
      }
    }
    return client;
  }

  getNPById(np_id: any, nps: any = []) {
    let np: any = null;
    for (let idx = 0; idx < nps.length; idx++) {
      if ((nps[idx].account_id != "") && (nps[idx].user.user_id == np_id)) {
        np = nps[idx];
        break;
      }
    }
    return np;
  }

  getFEById(fe_id: any, fes: any = []) {
    let fe: any = null;
    for (let idx = 0; idx < fes.length; idx++) {
      if ((fes[idx].account_id != "") && (fes[idx].account_id == fe_id)) {
        fe = fes[idx];
        break;
      }
    }
    return fe;
  }

  ngOnDestroy(): void {
    this.dataService.offFcmNotificationReceived(this.subscriber_id);
    this.dataService.offLoggedOut(this.subscriber_id);
    this.dataService.offAPIError(this.subscriber_id);
  }
}
