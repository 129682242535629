import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '../../constants';
import { NotificationService } from '../../services/notification.service';
import { JqueryUtils } from '../../utils/jquery.utils';
import { DataService } from '../../services/data.service';
import { DistrictService } from '../../services/api/district.service';
import { StateService } from '../../services/api/state.service';
import { StoreService } from '../../services/api/store.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit, OnDestroy {

  sess_acc: any;
  offset = 0;
  count = 10;
  totalItems = 0;
  stores: any = [];
  states: any = [{_id: -1, name: "Select", tag: "select"}];
  locations: any = [{_id: -1, name: "Select", tag: "select"}];
  saveStoreParams: any = {
    header: '',
    btn_txt: '',
    store: { phone_numbers: [], emails: [], address: { } }
  };
  fcmNotificationParams: any = { header: '', msg: '' };
  tableParams: any = {};
  searchTxt: any;
  subscriber_id: any = 'store';

  constructor(private storeService: StoreService, private dataService: DataService,
    private districtService: DistrictService, private stateService: StateService,
    private ns: NotificationService, private utilityService: UtilityService,
    private router: Router, private jq: JqueryUtils) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.loadStores();
    this.loadStates((states: any) => {
      this.loadLocations(null, null);
    });
    this.dataService.onFcmNotificationReceived(this.subscriber_id).subscribe(res => {
      this.fcmNotificationParams = {
        header: res.data.data_title,
        msg: res.data.data_body
      };
      this.jq.openModal('fcmNotificationModal');
    });
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
  }

  onPageSelected(currentPage: any) {
    this.offset = currentPage * this.count;
    this.loadStores();
  }

  loadStores() {
    if ((this.searchTxt != null) && (this.searchTxt.trim() != "")) {
      this.searchStore();
    } else {
      this.getStores();
    }
  }

  searchStore() {
    let input = {
      "store_code": this.searchTxt
    };
    this.storeService.searchStore(input).subscribe((response: any) => {
      this.stores = [];
      this.stores = response.store;
      this.totalItems = response.count;
      this.calculateTableParams();
    });
  }

  getStores() {
    let input = {
      "offset": this.offset,
      "limit": this.count,
    };
    this.storeService.getStore(input).subscribe((response: any) => {
      this.stores = [];
      this.stores = response.stores;
      this.totalItems = response.count;
      this.calculateTableParams();
    });
  }

  loadStates(cb: any) {
    this.states = [];
    this.stateService.getStates().subscribe((response: any) => {
      let states: any = [{_id: -1, name: "Select", tag: "select"}];
      response.states.forEach((state: any, idx: any) => {
        states.push({_id: idx, name: state, tag: state.toLowerCase() })
      });
      this.states = states;
      if (cb) {
        cb(states);
      }
    });
  }

  loadLocations(cb: any, state: any) {
    this.locations = [];
    let ip: any = { state: (state == null) ? this.states[0].name : state.name };
    this.districtService.getDistricts(ip).subscribe((response: any) => {
      let locations: any = [{_id: -1, name: "Select", tag: "select"}];
      response.districts.forEach((district: any, idx: any) => {
        locations.push({_id: idx, name: district, tag: district.toLowerCase() })
      });
      this.locations = locations;
      if (cb) {
        cb(locations);
      }
    });
  }

  calculateTableParams() {
    this.tableParams = {
    };
  }

  display_store_phones(phones: any) {
    return phones.join(", ");
  }

  display_store_emails(emails: any) {
    return emails.join(", ");
  }

  display_store_address(address: any) {
    let st_details: any = `${address?.locality || ''}, ${address?.state || ''}`;
    if (st_details.replace(",", "").trim() == "") {
      st_details = "";
    }
    return [
      address?.line1 || '',
      address?.line2 || '',
      address?.line3 || '',
      st_details
    ];
  }

  openAddStoreModal() {
    this.loadLocations((locations: any) => {
      this.openSaveStoreModal(false, { store: { phone_numbers: [], emails: [], address: {} } });
    }, this.states[1]);
  }

  openUpdateStoreModal(store: any) {
    let state = this.getStateByName(store.address.state);
    this.loadLocations((locations: any) => {
      this.openSaveStoreModal(true, { store });
    }, state);
  }

  viewStoreBarcodes(store: any) {
    let root: any = (this.sess_acc.user_type == 'admin') ? `/${CONSTANTS.ROUTES.ROOT.ADMIN}` : `/${CONSTANTS.ROUTES.ROOT.USER}`;
    this.router.navigate([root, CONSTANTS.ROUTES.STORE_BARCODES], { queryParams: {store: store.code}});
  }

  openSaveStoreModal(edit: any, data: any) {
    this.createSaveStoreParams({edit, data}, this.states, this.locations);
    this.jq.openModal('saveStoreModal');
  }

  closeSaveStoreModal() {
    this.saveStoreParams = {
      header: '',
      btn_txt: '',
      store: { phone_numbers: [], emails: [], address: { } }
    };
    this.jq.closeModal('saveStoreModal');
  }

  onSaveStoreLocationSelected() {
  }

  onSaveStoreStateSelected(params: any) {
    let state: any = this.getStateById(params.selectedStateId);
    if (state) {
      this.loadLocations((locations: any) => {
        params.locations = locations;
      }, state);
    }
  }

  createSaveStoreParams(opts: any, states: any = [], locations: any = []) {
    let selectedStateId: any = "", selectedLocationId: any = "";
    if (states.length > 0) {
      let state: any = this.getStateByName(opts.data.store.address?.state);
      if (state) {
        selectedStateId = state._id;
      } else {
        selectedStateId = states[0]._id;
      }
    }
    if (locations.length > 0) {
      let location: any = this.getLocalityByName(opts.data.store.address?.locality);
      if (location) {
        selectedLocationId = location._id;
      } else {
        selectedLocationId = locations[0]._id;
      }
    }
    this.saveStoreParams = {
      header: opts.edit ? 'Update Store': 'Add Store',
      btn_txt: opts.edit ? 'UPDATE' : 'ADD',
      states: states,
      locations: locations,
      selectedStateId: selectedStateId,
      selectedLocationId: selectedLocationId,
      store: {
        _id: opts.data.store._id || '',
        name: opts.data.store.name || '',
        email_str: opts.data.store.emails.join(", ") || '',
        phone_str: opts.data.store.phone_numbers.join(", ") || '',
        address: opts.data.store.address || {}
      },
      edit: opts.edit
    };
  }

  saveStore(params: any) {
    params = JSON.parse(JSON.stringify(params))
    params.store.address = params.store.address || {};
    let ip: any = {};
    let state: any = this.getStateById(params.selectedStateId);
    if (state) {
      params.store.address.state = state.tag;
    }
    let locality: any = this.getLocalityById(params.selectedLocationId);
    if (locality) {
      params.store.address.locality = locality.tag;
    }
    let emails: any = [], phone_numbers: any = [];
    params.store.email_str.split(",").forEach((e: any) => emails.push(e.trim()));
    params.store.phone_str.split(",").forEach((e: any) => phone_numbers.push(e.trim()));
    if (params.edit) {
      ip = {
        store_id: params.store._id,
        name: params.store.name,
        emails: emails,
        phone_numbers: phone_numbers,
        address: params.store.address
      };
    } else {
      ip = {
        name: params.store.name,
        emails: emails,
        phone_numbers: phone_numbers,
        address: params.store.address
      };
    }
    let func: any = params.edit ? this.storeService.updateStore : this.storeService.addStore;
    func(ip).subscribe((response: any) => {
      if (params.edit) {
        this.ns.showAlert(CONSTANTS.SUCCESS, "Store updated successfully")
      } else {
        this.ns.showAlert(CONSTANTS.SUCCESS, "Store added successfully")
      }
      this.loadStores();
      this.createSaveStoreParams({edit: false, data: { store:{ phone_numbers: [], emails: [], address: {} }}});
      this.jq.closeModal('saveStoreModal');
    });
  }

  getStateById(state_id: any) {
    let sid: any = '';
    for (let idx = 0; idx < this.states.length; idx++) {
      sid = `${this.states[idx]._id}`;
      if ((sid != '-1') && (sid == state_id)) {
        return this.states[idx];
      }
    }
  }

  getLocalityById(locality_id: any) {
    let lid: any = '';
    for (let idx = 0; idx < this.locations.length; idx++) {
      lid = `${this.locations[idx]._id}`;
      if ((lid != '-1') && (lid == locality_id)) {
        return this.locations[idx];
      }
    }
  }

  getStateByName(name: any) {
    for (let idx = 0; idx < this.states.length; idx++) {
      if (this.states[idx].name.toLowerCase() == name?.toLowerCase()) {
        return this.states[idx];
      }
    }
  }

  getLocalityByName(name: any) {
    for (let idx = 0; idx < this.locations.length; idx++) {
      if (this.locations[idx].name.toLowerCase() == name?.toLowerCase()) {
        return this.locations[idx];
      }
    }
  }

  clearSearch() {
    this.searchTxt = '';
    this.loadStores();
  }

  ngOnDestroy(): void {
    this.dataService.offFcmNotificationReceived(this.subscriber_id);
    this.dataService.offLoggedOut(this.subscriber_id);
  }
}
