<div class="container px-0">
    <div class="row sub-header-buffer">
        <div class="col-8">
            <div class="row">
                <div class="col">
                    <div class="font-weight-bold">From</div>
                    <hr class="hr-adjusted"/>
                    <div class="mt-1">{{ manifest?.owner_np?.user?.full_name }}</div>
                </div>
                <div class="col">
                    <div class="font-weight-bold">To</div>
                    <hr class="hr-adjusted"/>
                    <div class="mt-1">{{ manifest?.np?.user?.full_name }}</div>
                </div>
                <div class="col">
                    <div class="font-weight-bold">Pickup City</div>
                    <hr class="hr-adjusted"/>
                    <div class="mt-1">{{ manifest?.pickup_city.toUpperCase() }}</div>
                </div>
                <div class="col">
                    <div class="font-weight-bold">Delivery City</div>
                    <hr class="hr-adjusted"/>
                    <div class="mt-1">{{ manifest?.delivery_city.toUpperCase() }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row sub-header-buffer">
        <div class="col-md-12">
            <table class="table ecom-table">
                <thead class="table-header">
                    <tr>
                        <th>
                            <div>Date</div>
                        </th>
                        <th>
                            <div>Origin</div>
                            <div>Destination</div>
                        </th>
                        <th *ngIf="tableParams.show_cd">
                            <div>Coloader Name</div>
                            <div>Coloader Phone</div>
                            <div>Coloader City</div>
                        </th>
                        <th>
                            <div>AWB No</div>
                        </th>
                        <th>
                            <div>Total Box</div>
                            <div>Total Weight</div>
                        </th>
                        <th>
                            <div>Invoice Count</div>
                        </th>
                        <th *ngIf="tableParams.show_actions">
                            <div>Actions</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let shipment of shipments">
                        <td>
                            <div>{{shipment.created_date}}</div>
                        </td>
                        <td>
                            <div>{{shipment.details?.pickup_city.toUpperCase()}}</div>
                            <div>{{shipment.details?.delivery_city.toUpperCase()}}</div>
                        </td>
                        <td *ngIf="tableParams.show_cd">
                            <div>{{shipment.coloader?.name}}</div>
                            <div>{{shipment.coloader?.phone}}</div>
                            <div>{{shipment.coloader?.city}}</div>
                        </td>
                        <td>
                            <div>{{shipment.details?.bar_code}}</div>
                        </td>
                        <td>
                            <div>
                                <span>{{get_scanned_count(shipment)}} / </span>
                                {{shipment.details?.total_box}}
                            </div>
                            <div>{{shipment.details?.total_weight}}</div>
                        </td>
                        <td>
                            <a [routerLink]="['/invoicedetails', {'id': shipment.id,'waybill':shipment.details?.order_no, 'token': token}]"
                                routerLinkActive="active" target="_blank">
                                {{shipment.details?.invoice_count}}
                            </a>
                        </td>
                        <td *ngIf="tableParams.show_actions">
                            <i class="fas fa-arrow-right-from-bracket" *ngIf="tableParams.show_scanout"
                                (click)="scanOut(shipment)"></i>
                            <i class="fas fa-barcode pl-2" (click)="showBarcodes(shipment)"></i>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="15" class="center-align">
                            <div class="row sub-header-buffer float-right" *ngIf="totalItems > 0">
                                <div class="col-md-12 pagenation">
                                    <app-pagination [totalItems]="totalItems"
                                        (onPrevSelected)="onPageSelected($event)" (onNextSelected)="onPageSelected($event)"
                                        (onFirstSelected)="onPageSelected($event)" (onLastSelected)="onPageSelected($event)"
                                        (onPageSelected)="onPageSelected($event)">
                                    </app-pagination>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="selectNPModal" tabindex="-1" role="dialog"
    aria-labelledby="selectNPModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content py-3">
            <div class="modal-body px-5">
                <div class="row">
                    <div class="col-md-10">
                        <h5>Assign Network Partner</h5>
                    </div>
                    <div class="col-md-2">
                        <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close"
                            style="cursor: pointer" (click)="closeSelectNPModal()">&times;</span>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-md-12">
                        <label>Select Network Partner</label>
                        <div class="form-group">
                            <select class="form-select select-dd" [(ngModel)]="selNPId" (change)="onSelNP()">
                                <option *ngFor="let np of nps" [value]="np._id"> {{ np.full_name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row sub-header-buffer">
                    <div class="col-md-12 text-center">
                        <button class="btn btn-ecom px-5" (click)="selectNPAndScanOut(assignParams)">
                            Assign
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade mt-0" id="manifestSLBarcodeModal" tabindex="-1" aria-labelledby="manifestSLBarcodeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content rounded-0">
            <div class="modal-body" id="print-section">
                <div class="row pt-3">
                    <div class="col-md-auto" *ngFor="let barcode of barcodes">
                        <ngx-barcode6 [bc-format]="'CODE128'" [bc-value]="barcode.barcode" [bc-width]="2"
                            [bc-height]="100" [bc-text-align]="'center'" [bc-font-size]="10"
                            [bc-display-value]="true"></ngx-barcode6>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="closeBarcodeModal()">Cancel</button>
                <button type="button" class="btn btn-primary" [useExistingCss]="true" printSectionId="print-section"
                    ngxPrint (click)="printBarcodes()">Print</button>
            </div>
        </div>
    </div>
</div>