import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipmentService } from '../../services/api/shipment.service';
import { NotificationService } from '../../services/notification.service';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utility.service';
import { CONSTANTS } from '../../constants';
import { JqueryUtils } from '../../utils/jquery.utils';

@Component({
  selector: 'app-manifest-shipment-list',
  templateUrl: './manifest-shipment-list.component.html',
  styleUrls: ['./manifest-shipment-list.component.scss']
})

export class ManifestShipmentListComponent implements OnInit {

  sess_acc: any;
  token: any;
  offset: any;
  manifest_id: any;
  selNPId: any;

  manifest: any = {};
  nps: any = [];
  shipments: any = [];
  totalItems: any = 0;
  tableParams: any = {};
  assignParams: any = {};
  barcodes: any = [];
  subscriber_id: any = 'manfiest-sl';

  constructor(private shipmentService: ShipmentService, private dataService: DataService, private ns: NotificationService,
    private utilityService: UtilityService, public route: ActivatedRoute, private router: Router,
    private jq: JqueryUtils) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.token = sessionStorage.getItem(CONSTANTS.COOKIE.HEADER);
    this.route.queryParams.subscribe(params => {
      this.manifest_id = params['manifest_id'];
    });
    if (!this.manifest_id) {
      let root: any = (this.sess_acc.user_type == 'admin') ? `/${CONSTANTS.ROUTES.ROOT.ADMIN}` : `/${CONSTANTS.ROUTES.ROOT.USER}`;
      this.router.navigate([root, CONSTANTS.ROUTES.DELIVERY_MANIFEST]);
    } else {
      this.loadShipments();
    }
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
  }

  loadShipments() {
    let ip: any = {
      manifest_id: [this.manifest_id],
      index: this.offset,
      count: 20,
    };
    this.shipmentService.getManifestShipments(ip).subscribe((response: any) => {
      // let shipments: any = [];
      // response.shipments.forEach((shipment: any) => {
      //   shipments.push(shipment.shipment);
      // });
      // this.shipments = shipments;
      this.shipments = response.shipments;
      this.totalItems = response.count;
      // this.manifest = response.shipments[0].manifest;
      this.manifest = response.manifest;
      this.loadNetworkPartners();
      this.tableParams = {
        show_scanout: (this.shipments.length > 0) && (this.shipments[0].shipment_status == CONSTANTS.SHIPMENT_TYPE.INTRANSIT) && false,
        show_actions: true
      };
    });
  }

  loadNetworkPartners() {
    let ip: any = {
      date: this.manifest.date,
      city: this.manifest.delivery_city
    };
    this.shipmentService.getManifestNps(ip).subscribe((response: any) => {
      this.nps = [];
      this.nps = response.network_partners;
      this.selNPId = this.nps[0]._id;
    });
  }

  onSelNP() { }

  showBarcodes(shipment: any) {
    let barcodes: any = [];
    for (let iidx = 0; iidx < shipment.details.invoice_count; iidx++) {
      barcodes.push({
        index: iidx,
        barcode: shipment.details.bar_code,
        shipment_id: shipment.id,
        label: `Slide ${iidx}`
      });
    }
    this.barcodes = barcodes;
    this.jq.openModal('manifestSLBarcodeModal');
  }

  closeBarcodeModal() {
    this.barcodes = [];
    this.jq.closeModal('manifestSLBarcodeModal');
  }

  printBarcodes() {
    this.barcodes = [];
    this.jq.closeModal('manifestSLBarcodeModal');
  }

  onPageSelected(currentPage: any) {
    this.offset = currentPage * 20;
    this.loadShipments();
  }

  get_scanned_count(shipment: any) {
    if (this.manifest.delivery_city.toLowerCase() != this.manifest.pickup_city.toLowerCase()) {
      return '' + (shipment.details.intransit.scanned_count || 0);
    } else {
      return '' + (shipment.details.out_for_delivery.scanned_count || 0);
    }
  }

  scanOut(shipment: any) {
    let scanned_count: any = 0;
    if (this.manifest.delivery_city != this.manifest.pickup_city) {
      scanned_count = shipment.details.intransit.scanned_count;
    } else {
      scanned_count = shipment.details.out_for_delivery.scanned_count;
    }
    if (scanned_count > 0) {
      this.requestScanout(shipment);
    } else {
      this.assignParams = {
        shipment: shipment
      };
      this.jq.openModal('selectNPModal');
    }
  }

  selectNPAndScanOut(params: any) {
    let np: any = this.getNPById(this.selNPId);
    if (!np) {
      this.ns.showAlert(CONSTANTS.ERROR, "Please select atleast one Network Partner.");
      return;
    }
    this.requestScanout(params.shipment, np);
  }

  closeSelectNPModal() {
    this.assignParams = { };
    this.jq.closeModal('selectNPModal');
  }

  requestScanout(shipment: any, np: any = null) {
    let ip: any = {
      force: 1,
      barcodes: [shipment.details.bar_code]
    };
    if (np != null) {
      ip.np_id = np._id;
    }
    this.shipmentService.scanout(ip).subscribe((response: any) => {
      this.jq.closeModal('selectNPModal');
    });
  }

  getNPById(np_id: any) {
    let np: any = null;
    for (let idx = 0; idx < this.nps.length; idx++) {
      if ((this.nps[idx]._id !== "") && (this.nps[idx]._id === np_id)) {
        np = this.nps[idx];
        break;
      }
    }
    return np;
  }
}
