import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { Login } from '../../models/request/login';
import { CONSTANTS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends ApiService {

  public doLogin(login: Login) {
    return this.post(CONSTANTS.API.LOGIN, login);
  }
  public logout() {
    return this.get(CONSTANTS.API.LOGOUT);
  }
  public getAccounts(data: any) {
    return this.getQueryParams(CONSTANTS.API.ACCOUNTS, data);
  }
  addAccounts = (data: any) => {
    return this.post(CONSTANTS.API.ACCOUNT, data);
  }
  updateAccounts = (data: any) => {
    return this.put(CONSTANTS.API.ACCOUNT, data);
  }
   public deleteAccounts(data: any) {
    return this.delete(CONSTANTS.API.ACCOUNT, data);
  }
  public resetPassword(data: any) {
    return this.put(CONSTANTS.API.RESET_PASSWORD, data);
  }
  public changePassword(data: any) {
    return this.put(CONSTANTS.API.CHANGE_PASSWORD, data);
  }
  public searchUser(data: any) {
    return this.getQueryParams(CONSTANTS.API.SEARCH_ACCOUNT, data);
  }
}
