import { Component, Input } from '@angular/core';
import { AccountService } from '../../../services/api/account.service';
import { DataService } from '../../../services/data.service';
import { NotificationService } from '../../../services/notification.service';
import { ShipmentService } from '../../../services/api/shipment.service';
import { JqueryUtils } from '../../../utils/jquery.utils';
import { CONSTANTS } from '../../../constants';
import { PrintService } from '../../../services/api/print.service';

@Component({
  selector: 'app-rp-list',
  templateUrl: './rp-list.component.html',
  styleUrls: ['./rp-list.component.scss']
})
export class RpListComponent {

  @Input('shipments')
  shipments: any = [];

  @Input('totalItems')
  totalItems = 0;

  @Input('shipmentType')
  shipmentType = '';

  sess_acc: any;
  token: any;
  offset = 0;
  tableParams: any = {
    has_actions: false,
    show_delivered: false,
    assign_fe: false,
    deliver: false,
    barcode: false
  };
  rpSLBarcodeModalName: any = [];
  assignParams: any = { };
  confirmationParams: any = { };
  viewBarcodeParams: any = { 
    barcodes: []
  };
  subscriber_id: any = 'rp-list';
  all_selected: any = false;
  any_selected: any = false;

  constructor(private accountService: AccountService, private dataService: DataService,
    private printService: PrintService, private shipmentService: ShipmentService, private ns: NotificationService,
    private jq: JqueryUtils) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.token = sessionStorage.getItem(CONSTANTS.COOKIE.HEADER);
    this.rpSLBarcodeModalName = `${this.shipmentType}_rp_barcode_modal`;
    this.dataService.onRPShipmentListTabSelected(this.subscriber_id).subscribe(res => {
      this.calculateTableParams(res.shipment_type);
    });
    this.dataService.onRPAssign(this.subscriber_id).subscribe(res => {
      this.assignShipments();
    });
  }

  // updateShipments() {
  //   for (let s of this.shipments) {
  //     s.selected = false;
  //   }
  //   this.dataService.invokeRPShipmentSelected({ all_selected: this.all_selected, any_selected: this.any_selected  });
  // }

  onSelExecutive(params: any) {
    for (let idx = 0; idx < params.fes.length; idx++) {
      params.fes[idx].selected = (params.selExecId == params.fes[idx].user.user_id);
    }
  }

  assignExecutive(params: any) {

    let fe: any = this.getFEById(params.selExecId, params.fes);
    let ip: any = {
      barcodes: params.barcodes,
      fe_id: fe.user.user_id,
    };
    this.shipmentService.assignRPShipment(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "FE Assigned successfully");
      this.dataService.rpShipmentListPageSelected({offset: this.offset, count: CONSTANTS.ITEM_PER_PAGE});
      this.jq.closeModal('rpAssignSLFEModal');
    });
  }

  assignShipments() {
    let selected: any = this.getSelectedShipments();
    this.openAssignSLFEModal(selected, true);
  }

  getSelectedShipments() {
    let selected: any = [];
    for (let s of this.shipments) {
      if (s.selected) {
        selected.push(s);
      }
    }
    return selected;
  }

  onPageSelected(currentPage: any) {
    this.offset = currentPage * 20;
    this.dataService.rpShipmentListPageSelected({offset: this.offset, count: CONSTANTS.ITEM_PER_PAGE});
  }

  onAllShipmentsSelected(event: any) {
    this.all_selected = event.target.checked;
    this.any_selected = event.target.checked;
    for (let idx = 0; idx < this.shipments.length; idx++) {
      this.shipments[idx].selected = this.all_selected;
    }
    this.dataService.invokeRPShipmentSelected({ all_selected: this.all_selected, any_selected: this.any_selected  });
  }

  onShipmentSelected(shipment: any, event: any) {
    shipment.selected = event.target.checked;
    let all_selected: any = true, any_selected = false;
    for (let idx = 0; idx < this.shipments.length; idx++) {
      all_selected = all_selected && (this.shipments[idx].selected || false);
      any_selected = any_selected || (this.shipments[idx].selected || false);
    }
    this.all_selected = all_selected;
    this.any_selected = any_selected;
    this.dataService.invokeRPShipmentSelected({ all_selected: this.all_selected, any_selected: this.any_selected  });
  }

  calculateTableParams(shipment_type: any) {
    let assign_fe: any = [CONSTANTS.RP_SHIPMENT_TYPE.PICKED_FROM_CUSTOMER].indexOf(shipment_type) > -1;
    assign_fe = assign_fe && (this.sess_acc.user_type == CONSTANTS.USER_TYPES.NETWORK_PARTNER);
    let barcode: any = true;
    let deliver: any = [CONSTANTS.RP_SHIPMENT_TYPE.PICKED_FROM_CUSTOMER].indexOf(shipment_type) > -1;
    deliver = deliver && (this.sess_acc.user_type == null);
    let show_delivered: any = [CONSTANTS.RP_SHIPMENT_TYPE.DELIVERED].indexOf(shipment_type) > -1;
    let show_selection: any = [CONSTANTS.RP_SHIPMENT_TYPE.PICKED_FROM_CUSTOMER].indexOf(shipment_type) > -1;
    show_selection = (this.sess_acc.user_type == CONSTANTS.USER_TYPES.NETWORK_PARTNER);
    let tableParams: any = {
      has_actions: assign_fe || barcode || deliver,
      assign_fe: assign_fe,
      barcode: barcode,
      deliver: deliver,
      show_delivered: show_delivered,
      show_selection: show_selection
    };
    this.tableParams = tableParams;
  }

  getFEs(np_id: any, cb: any) {
    let input = { "type": "field_exec", "executive_type": "delivery_executive", "np_id": np_id };
    this.accountService.getAccounts(input).subscribe((response: any) => {
      if (cb) {
        cb(response.accounts);
      }
    });
  }

  get_location(shipment: any) {
    let status_obj: any = {};
    if (shipment.shipment_status == CONSTANTS.RP_SHIPMENT_TYPE.DELIVERED) {
      status_obj = shipment.details?.rp_delivered;
    }
    return status_obj?.location;
  }

  has_sign(shipment: any) {
    return (shipment.details.rp_delivered?.sign_fn) && (shipment.details.rp_delivered?.sign_fn != null);
  }

  openAssignSLFEModal(shipments: any, multi: any) {
    this.getFEs(shipments[0].details.picked_from_customer.np.account_id, (fes: any) => {
      let barcodes: any = [];
      shipments.forEach((s: any) => {
        barcodes.push(s.details.bar_code)
      });
      this.assignParams = {
        barcodes: barcodes,
        fes: fes,
        selExecId: multi ? '' : shipments[0].details.delivery_executive_details.user_id
      };
      this.onSelExecutive(this.assignParams);
      this.jq.openModal('rpAssignSLFEModal');
    });
  }

  closeAssignSLFEModal() {
    this.assignParams = { };
    this.jq.closeModal('rpAssignSLFEModal');
  }

  closeBarcodeModal() {
    this.viewBarcodeParams = { barcodes: [] };
    this.jq.closeModal(this.rpSLBarcodeModalName);
  }

  showBarcodes(shipment: any) {
    let barcodes: any = [];
    for (let iidx = 0; iidx < shipment.details.total_box; iidx++) {
      barcodes.push({
        index: iidx,
        barcode: shipment.details.bar_code,
        box_no: iidx + 1,
        obj: shipment,
        label: `Slide ${iidx}`
      });
    }
    this.viewBarcodeParams = {
      barcodes
    };
    this.jq.openModal(this.rpSLBarcodeModalName);
  }

  printBarcodes(params: any) {
    this.viewBarcodeParams = { };
    let barcodes: any = [];
    for (let idx = 0; idx < params.barcodes.length; idx++) {
      barcodes.push({
        barcode: params.barcodes[idx].obj.details.bar_code,
        box_no: '1',
        name: params.barcodes[idx].obj.created_for?.user?.full_name,
        obj: {
          order_no: '',
          mode: '',
          store: params.barcodes[idx].obj.details.store,
          total_weight: ''
        }
      });
    }
    let ip: any = { codes: barcodes };
    this.printService.printBarcodes(ip).subscribe((response: any) => {

    });
    this.jq.closeModal(this.rpSLBarcodeModalName);
  }

  getFEById(fe_id: any, fes: any = []) {
    let fe: any = null;
    for (let idx = 0; idx < fes.length; idx++) {
      if ((fes[idx].user.user_id != 0) && (fes[idx].user.user_id == fe_id)) {
        fe = fes[idx];
        break;
      }
    }
    return fe;
  }

  openConfirmDeliverModal(shipment: any) {
    this.confirmationParams = {
      shipment: shipment,
      header: 'Deliver',
      msg: 'Are you want to deliver this shipment?'
    };
    this.jq.openModal('rpConfirmModal');
  }

  closeConfirmModal(params: any) {
    this.confirmationParams = {};
    this.jq.closeModal('rpConfirmModal');
  }

  confirm(params: any) {
    let ip: any = {
      shipment_ids: [params.shipment.id]
    };
    this.shipmentService.deliverRPShipment(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "Shipment Delivered successfully");
      this.dataService.rpShipmentListPageSelected({offset: this.offset, count: CONSTANTS.ITEM_PER_PAGE});
      this.jq.closeModal('rpConfirmModal');
    });
  }

  downloadSign(shipment: any) {
    let input = {
      "shipment_id": shipment.id,
      "name": "sign",
    };
    this.shipmentService.downloadData(input).subscribe((response: any) => {
      response.text().then((r: any) => {
        let error;
        try {
          let json = JSON.parse(r);
          if (json.response_code) {
            this.ns.showAlert(CONSTANTS.ERROR, json.errors[0].data);
          } else {
            error = new Error();
          }
        } catch (e) {
          error = e;
        }
        if (error) {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(response);
          a.download = 'sign.zip';
          // start download
          a.click();
          this.ns.showAlert(CONSTANTS.SUCCESS, "Downloaded successfully");
        }
      });
    });
  }
}
