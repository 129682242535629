import { environment } from "../../../../environments/environment"

export const CONSTANTS = {

  API: {
    BASE_URL: environment.baseUrl,
    CHANNEL_BASE_URL: environment.channelUrl,
    SOCKET_URL: environment.socketUrl,
    CONFIG_DATA: "cdata/datas/?keys=l1c,l2c",
    CDATA: "cdata/client",
    LOGOUT: "auth/logout",
    LOGIN: "auth/login",
    ACCOUNT:"account",
    ACCOUNTS: "accounts",
    RESET_PASSWORD:"account/resetpwd",
    CHANGE_PASSWORD:"account/pwd",
    SEARCH_ACCOUNT:"account/search",

    BARCODE: {
      STORE_BARCODE_LIST: "barcode",
      GENERATE_STORE_BARCODE: "barcode/generate",
    },
    CHANNEL: {
      GET_OTP: "test/otp",
    },
    DISTRICT: {
      GET_DISTRICTS: "districts",
      ADD_DISTRICT: "district",
      UPDATE_DISTRICT_TAG: "district/tag",
    },
    FCM: {
      SAVE_TOKEN: "fcm",
    },
    REPORT: {
      GET_REPORT: 'report',
      GET_RP_REPORT: 'report/rp',
      DOWNLOAD_REPORT: 'report/dl',
      DOWNLOAD_RP_REPORT: 'report/rp/dl'
    },

    SHIPMENT: {
      GET_SHIPMENTS: "shipments",
      GET_RP_SHIPMENTS: "shipments/rp",
      GET_INVOICE_DETAILS: "shipment/invoices",
      CREATE_SHIPMENT:"shipment/uploadxls",
      VERIFY_UPLOAD_XLS:"shipment/verify/uploadxls",
      ASSIGN_SHIPMENT:"shipment/assign",
      ASSIGN_RP_SHIPMENT:"shipment/rp/fe",
      DELIVER_RP_SHIPMENT:"shipment/rp/deliver",
      CANCEL_SHIPMENT:"shipment/cancel",
      SAVE_COLOADER_DETAILS:"shipment/coloader",
      GET_BARCODES:"shipment/barcodes",
      ASSIGN_FE:"shipment/fe",
      UNASSIGN_FE:"shipment/fe",
      DASHBOARD: "dashboard",
      SUDS: "suds",
      PROCESS_SUD: "sud/process",
      GET_SUD_SHIPMENTS: "sud/shipments",
      GET_UPLOADED_XLS: "sud/uploadxls",
      SEARCH:"shipment/search",
      DOWNLOAD:"shipment/download",
      SCAN_IN:"shipment/common/scanin",
      SCAN_OUT:"shipment/common/scanout",
      OFD:"shipment/ofd",
      GET_MANIFEST:"manifest",
      GET_RP_MANIFEST:"manifest/rp",
      GET_PICKUP_MANIFEST:"manifest/pickup",
      GENERATE_PICKUP_MANIFEST:"manifest/generate/pickup",
      GET_MANIFEST_CITIES:"manifest/cities",
      GET_MANIFEST_NPS:"manifest/np",
      GET_RP_MANIFEST_FES:"manifest/rp/fe",
      ASSIGN_MANIFEST_NP:"manifest/np",
      GENERATE_MANIFEST:"manifest/generate",
      GENERATE_RP_MANIFEST:"manifest/rp/close",
      GET_MANIFEST_SHIPMENTS:"manifest/shipments",
      GET_RP_MANIFEST_SHIPMENTS:"manifest/rp/shipments",
      MERGE_MANIFESTS:"manifest/merge",
      DOWNLOAD_MANIFEST:"manifest/download",
      DOWNLOAD_RP_MANIFEST:"manifest/download/rp",
      DOWNLOAD_PICKUP_MANIFEST:"manifest/download/pickup",
    },
    STATE: {
      GET_STATES: "state",
    },
    STORE: {
      GET_STORE: "store",
      ADD_STORE: "store",
      UPDATE_STORE: "store",
      SEARCH_STORE: "store/search",
      STORE_BARCODE_LIST: "store/barcode",
    },
    PRINTER: {
      PRINT_BARCODES: "barcode/print",
    }
  },
  STATUS: {
    COMPLETED: 'completed',
    CANCELLED: 'cancelled'
  },
  COOKIE: {
    HEADER: "hhh",
    ISLOGGEDIN: "ili",
    ACCOUNT: "account"

  },
  PREFERENCE: {
    USER_ID: 'userId',
    USERNAME: 'email',
    USERTYPE: "type",

  },
  DATE_FORMAT: {
    DDMMYYYY: "ddmmyyyy",
    YYYYMMDD: "yyymmdd",
    YYYY: "yyyy"
  },

  ROUTES: {
    ROOT: {
      ADMIN: 'admin',
      USER: 'user',
    },
    CLIENT: 'client',
    CREATE: 'create',
    DASHBOARD: 'dashboard',
    INVOICE_DETAILS: 'invoicedetails',
    FORWARD_REPORT: 'forward-report',
    RP_REPORT: 'rp-report',
    RESET: 'reset',
    USERS: 'users',
    STORES: 'stores',
    VIEW: 'view',
    RP_VIEW: 'rpview',
    PICKUP_MANIFEST: 'pickup-manifest',
    DELIVERY_MANIFEST: 'delivery-manifest',
    MANIFEST_SHIPMENT: 'manifestshipments',
    RP_MANIFEST: 'rp-manifest',
    RP_MANIFEST_SHIPMENT: 'rp-manifest-shipments',
    STORE_BARCODES: 'store-barcodes',
    UPLOAD_SHIPMENTS: 'uploadedshipments',
  },

  USER_TYPES: {
    ADMIN: 'admin',
    SUB_ADMIN: 'sub_admin',
    FIELD_EXEC: 'field_exec',
    NETWORK_PARTNER: 'np',
    NETWORK_PARTNER_SUB_ADMIN: 'np_sub_admin',
    NETWORK_PARTNER_FIELD_EXEC: 'np_fe',
    CLIENT: 'client'
  },

  SHIPMENT_TAB: {
    BOOKING: 'booking',
    ARRIVED_AT_NP_HUB: 'arrived_at_np_hub',
    INTRANSIT: 'intransit',
    OUT_FOR_DELIVERY: 'out_for_delivery',
    DELIVERED: 'delivered',
    PENDING: 'pending',
    REJECTED: 'rejected',
    CANCELLED: 'cancel',
    OPTIONS: {
      booking_created: 'booking_created',
      pickup_executive_assigned: 'pickup_executive_assigned',
      picked_from_warehouse: 'picked_from_warehouse',
      arrived_at_hub: 'arrived_at_hub',
      arrived_at_destination_hub: 'arrived_at_destination_hub'
    }
  },

  RP_SHIPMENT_TAB: {
    PICKED: 'picked_from_customer',
    DELIVERED: 'rp_delivered',
  },

  SHIPMENT_TYPE: {
    BOOKING_CREATED: 'booking_created',
    PE_ASSIGNED: 'pickup_executive_assigned',
    PICKED_FROM_WAREHOUSE: 'picked_from_warehouse',
    AT_HUB: 'arrived_at_hub',
    AT_DHUB: 'arrived_at_destination_hub',
    INTRANSIT: 'intransit',
    OUT_FOR_DELIVERY: 'out_for_delivery',
    DELIVERED: 'delivered',
    PENDING: 'pending',
    REJECTED: 'rejected',
    CANCELLED: 'cancel'
  },

  RP_SHIPMENT_TYPE: {
    PICKED_FROM_CUSTOMER: 'picked_from_customer',
    DELIVERED: 'rp_delivered',
  },

  SHIPMENT_DISPLAY_NAMES: {
    booking: {
      booking_created: 'Booking',
      pickup_executive_assigned: 'Executive Assigned',
      picked_from_warehouse: 'Warehouse Pickup',
      arrived_at_hub: 'At Hub',
      arrived_at_destination_hub: 'At Delivery Hub'
    },
    intransit: 'Intransit',
    out_for_delivery: 'Out For Delivery',
    delivered: 'Delivered',
    pending: 'Pending',
    rejected: 'Rejected',
    cancel: 'Cancelled'
  },

  RP_SHIPMENT_DISPLAY_NAMES: {
    picked_from_customer: 'Picked',
    rp_delivered: 'Delivered',
  },

  SHIPMENT_FLOWS: {
    FORWARD: 'courier',
    RP: 'reverse_pickup'
  },

  SUCCESS: "success",
  ERROR: "error",
  INFO: "info",

  ITEM_PER_PAGE: 20,
}
