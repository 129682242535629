import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipmentService } from '../../services/api/shipment.service';
import { AccountService } from '../../services/api/account.service';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utility.service';
import { CONSTANTS } from '../../constants';
import { NotificationService } from '../../services/notification.service';
import { JqueryUtils } from '../../utils/jquery.utils';

@Component({
  selector: 'app-rp-manifest',
  templateUrl: './rp-manifest.component.html',
  styleUrls: ['./rp-manifest.component.scss']
})
export class RPManifestComponent implements OnInit, OnDestroy {

  sess_acc: any;
  selDate: any;
  allow: any = {
    fe_selection: true
  };

  manifest_fes: any = [];

  manifests: any = [];
  shipments: any = [];

  fes: any;
  fcmNotificationParams: any = { header: '', msg: '' };
  subscriber_id: any = 'rp-manifest';

  constructor(private accountService: AccountService, private dataService: DataService,
    private shipmentService: ShipmentService, private ns: NotificationService,
    private utilityService: UtilityService, private jq: JqueryUtils, private router: Router) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.selDate = new Date().toISOString().substring(0, 10);
    this.calculate_allowed();
    this.onDateChanged(false);
    this.dataService.onFcmNotificationReceived(this.subscriber_id).subscribe(res => {
      this.fcmNotificationParams = {
        header: res.data.data_title,
        msg: res.data.data_body
      };
      this.jq.openModal('fcmNotificationModal');
    });
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
  }

  calculate_allowed() {
    let uts: any = CONSTANTS.USER_TYPES;
    this.allow = {
      fe_selection: [uts.ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
    };
  }

  onDateChanged(retain: any) {
    if (this.allow.fe_selection) {
      this.loadManifestFEs(this.selDate, this.onManifestFEsRetrieved.bind(this), {retain});
    } else {
      this.loadManifests();
    }
  }

  get_selected_fe() {
    for (let idx = 0; idx < this.manifest_fes.length; idx++) {
      if (this.manifest_fes[idx].selected) {
        return this.manifest_fes[idx];
      }
    }
  }

  select_fe(selFE: any) {
    this.manifest_fes.forEach((fe: any) => {
      fe.selected = (fe.account_id == selFE);
    });
  }

  onSelectedManifestFE(event: any) {
    let selFE: any = event.target.value;
    this.select_fe(selFE);
    this.loadManifests();
  }

  onManifestFEsRetrieved(opts: any, response: any) {
    let fes: any = [{ account_id: "0", selected: false, user: { full_name: 'All' } }];
    let selFE: any;
    if (opts.retain) {
      selFE = this.get_selected_fe();
    }
    this.manifest_fes = [];
    response.field_executive.forEach((fe: any) => {
      fe.selected = false;
      fes.push(fe);
    });
    this.manifest_fes = fes;
    if (!selFE) {
      this.manifest_fes[0].selected = true;
    } else {
      this.select_fe(selFE.account_id);
    }
    this.loadManifests();
  }

  openManifestGeneratedModal() {
    this.jq.openModal('rpManifestGeneratedModal');
  }

  closeManifest(manifest: any) {
    let ip: any = {
      shipment_manifest_id: [manifest._id]
    };
    this.shipmentService.generateRPManifest(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "Manifest Generate successfully");
      this.loadManifests();
    });
  }

  closeManifestGeneratedModal() {
    this.jq.closeModal('rpManifestGeneratedModal');
  }

  viewShipments(manifest: any) {
    let root: any = (this.sess_acc.user_type == 'admin') ? `/${CONSTANTS.ROUTES.ROOT.ADMIN}` : `/${CONSTANTS.ROUTES.ROOT.USER}`;
    this.router.navigate([root, CONSTANTS.ROUTES.RP_MANIFEST_SHIPMENT], { queryParams: {manifest_id: manifest._id}});
  }

  downloadShipments(manifest: any) {
    let ip: any = {
      manifest_id: manifest._id
    };
    this.shipmentService.downloadRPManifest(ip).subscribe((response: any) => {
      response.text().then((r: any) => {
        let error;
        try {
          let json = JSON.parse(r);
          if (json.response_code) {
            this.ns.showAlert(CONSTANTS.ERROR, json.errors[0].data);
          } else {
            error = new Error();
          }
        } catch (e) {
          error = e;
        }
        if (error) {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(response);
          a.download = 'manifest.xlsx';
          // start download
          a.click();
          // this.ns.showAlert(CONSTANTS.SUCCESS, "Downloaded successfully");
        }
      });
    });
  }

  loadManifestFEs(date: any, cb: any = null, cb_args: any = null) {
    let ip: any = {
      date: date
    };
    this.shipmentService.getRPManifestFEs(ip).subscribe((response: any) => {
      if (cb) {
        cb(cb_args, response);
      }
    });
  }

  loadManifests(retain_selection: any = false) {
    let fe_id: any = '';
    if (this.allow.fe_selection) {
      let fe: any = this.get_selected_fe();
      if (fe && (fe.account_id != '0')) {
        fe_id = fe.user.user_id;
      }
    }
    let ip: any = {
      date: this.selDate,
      fe_id: fe_id
    };
    this.shipmentService.getRPManifests(ip).subscribe((response: any) => {
      let ids: any = retain_selection ? this.getSelectedManifests() : [];
      this.manifests = [];
      this.manifests = response.manifests.map((manifest: any) => {
        manifest.selected = (ids.indexOf(manifest._id) > -1);
        return manifest;
      });
    });
  }

  refreshManifests() {
    this.onDateChanged(true);
  }

  can_close(manifest: any) {
    let close: any = (manifest.state == 'open') && (manifest.details.status == 'completed');
    if (close) {
      let uts: any = CONSTANTS.USER_TYPES;
      close = [uts.CLIENT].indexOf(this.sess_acc.user_type) > -1;
    }
    return close;
  }

  getSelectedManifests() {
    let ids: any = [];
    for (let idx = 0; idx < this.manifests.length; idx++) {
      if (this.manifests[idx].selected) {
        ids.push(this.manifests[idx]._id);
      }
    }
    return ids;
  }

  getManifestFEById(np_id: any) {
    let fe: any = null;
    for (let idx = 0; idx < this.manifest_fes.length; idx++) {
      if ((this.manifest_fes[idx].account_id !== "0") && (this.manifest_fes[idx].account_id === np_id)) {
        fe = this.manifest_fes[idx];
        break;
      }
    }
    return fe;
  }

  ngOnDestroy(): void {
    this.dataService.offFcmNotificationReceived(this.subscriber_id);
    this.dataService.offLoggedOut(this.subscriber_id);
  }
}
