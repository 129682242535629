<div class="container">
  <div class="row sub-header-buffer">
    <div class="col-md-4">
      <div class="input-group">
          <input type="text" class="form-control h-auto" [(ngModel)]="searchTxt" placeholder="Search by User Name,Phone" />
          <span class="input-group-btn search-border-style">
              <button class="btn btn-default">
                  <i class="fa fa-xmark text-danger" (click)="clearSearch()"></i>
              </button>
          </span>
      </div>
    </div>
    <div class="col-md-2">
      <button class="btn btn-ecom" (click)="searchUser()">
        Search
      </button>
    </div>
    <div class="col-md-3 pt-1">
      <select class="form-select select-dd" [(ngModel)]="selUserType" (change)="onSelectedUserType()">
        <option *ngFor="let ut of user_types" [value]="ut._id"> {{ ut.name }}</option>
      </select>
    </div>
    <div class="col-md-3">
      <button class="btn btn-ecom" (click)="openAddUserModal()">
        ADD
      </button>
    </div>
  </div>
  <div class="row sub-header-buffer">
    <table class="table ecom-table">
      <thead class="table-header">
        <tr>
          <th>
            <div>NAME</div>
          </th>
          <th>
            <div>PHONE</div>
          </th>
          <th>
            <div>EMAIL</div>
          </th>
          <th *ngIf="tableParams.show_np">
            <div>NETWORK PARTNER</div>
          </th>
          <th *ngIf="tableParams.show_locality">
            <div>LOCALITY</div>
          </th>
          <th *ngIf="tableParams.show_et">
            <div>CATEGORY</div>
          </th>
          <th>ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let acc of accounts">
          <td>
            <div>{{acc.user.full_name}}</div>
          </td>
          <td>
            <div>{{acc.user.phone}}</div>
          </td>
          <td>
            <div>{{acc.user.email}}</div>
          </td>
          <td *ngIf="tableParams.show_locality">
            <div>{{acc.details.locality.toUpperCase()}}</div>
          </td>
          <td *ngIf="tableParams.show_np">
            <div>{{acc.details.np?.full_name}}</div>
          </td>
          <td *ngIf="tableParams.show_et">
            <div>{{get_exec_type_name(acc.details.type)}}</div>
          </td>
          <td>
            <i class="fas fa-key" (click)="openResetPasswordModal(acc)">
            </i> &nbsp;
            <i class="fas fa-trash-alt" (click)="openDeleteUserModal(acc)">
            </i> &nbsp;
            <i class="fas fa-edit" (click)="openUpdateUserModal(acc)">
            </i>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="15" class="center-align">
            <div class="row sub-header-buffer float-right" *ngIf="totalItems > 0">
              <div class="col-md-12 pagenation">
                <app-pagination [totalItems]="totalItems"
                  (onPrevSelected)="onPageSelected($event)" (onNextSelected)="onPageSelected($event)"
                  (onFirstSelected)="onPageSelected($event)" (onLastSelected)="onPageSelected($event)"
                  (onPageSelected)="onPageSelected($event)">
                </app-pagination>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>

<div class="modal fade" id="delUserModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog delete-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title" id="myModalLabel"><i class="fa fa-warning text-red"></i>&nbsp;Delete User</h4>
      </div>
      <div class="modal-body" style="text-align: left;">
        <span>Are you sure want to delete this user ?<br>
          <label>{{deleteUserParams.selectedUserToDelete.user?.full_name}}</label></span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="deleteUser(deleteUserParams)">Delete</button>
        <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="saveUserModal" tabindex="-1" role="dialog"
  aria-labelledby="saveUserModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>{{ saveUserParams.header }}</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close"
              style="cursor: pointer" (click)="closeSaveUserModal()">&times;</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>FULL NAME</label>
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="saveUserParams.user.full_name" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>EMAIL</label>
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="saveUserParams.user.email" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>PHONE</label>
            <div class="form-group">
              <input type="text" autocomplete="off" class="form-control" [(ngModel)]="saveUserParams.user.phone" />
            </div>
          </div>
        </div>
        <div class="row" *ngIf="saveUserParams.show_np">
          <div class="col-md-12">
            <label>Network Partner</label>
            <div class="form-group">
              <select class="form-select select-dd" [(ngModel)]="saveUserParams.selectedNpId" (change)="onSaveUserNPSelected()">
                <option *ngFor="let np of saveUserParams.nps" [value]="np.account_id"> {{ np.user.full_name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="saveUserParams.show_fe_type">
          <div class="col-md-12">
            <label>Executive</label>
            <div class="form-group">
              <select class="form-select select-dd" [(ngModel)]="saveUserParams.selectedExecTypeId" (change)="onSaveUserExecTypeSelected()">
                <option *ngFor="let et of saveUserParams.executive_types" [value]="et._id"> {{ et.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="saveUserParams.show_locality">
          <div class="col-md-12">
            <label>State</label>
            <div class="form-group">
              <select class="form-select select-dd" [(ngModel)]="saveUserParams.selectedStateId" (change)="onSaveUserStateSelected(saveUserParams)">
                <option *ngFor="let state of saveUserParams.states" [value]="state._id"> {{ state.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="saveUserParams.show_locality">
          <div class="col-md-12">
            <label>City</label>
            <div class="form-group">
              <select class="form-select select-dd" [(ngModel)]="saveUserParams.selectedLocationId" (change)="onSaveUserLocationSelected()">
                <option *ngFor="let location of saveUserParams.locations" [value]="location._id"> {{ location.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!saveUserParams.edit">
          <div class="col-md-12">
            <label>PASSWORD</label>
            <div class="form-group">
              <input type="text" class="form-control" autocomplete="off" [(ngModel)]="saveUserParams.user.password" />
            </div>
          </div>
        </div>
        <div class="row" *ngIf="saveUserParams.show_address">
          <div class="col-md-12">
            <label>Booking Emails</label>
            <div class="form-group">
              <textarea class="form-control" placeholder="Comma Seperated Email Addresses" autocomplete="off" [(ngModel)]="saveUserParams.details.booking_emails"></textarea>
            </div>
          </div>
          <div class="col-md-12">
            <label>POD Emails</label>
            <div class="form-group">
              <textarea class="form-control" placeholder="Comma Seperated Email Addresses" autocomplete="off" [(ngModel)]="saveUserParams.details.pod_emails"></textarea>
            </div>
          </div>
          <div class="col-md-12">
            <label>Report Emails</label>
            <div class="form-group">
              <textarea class="form-control" placeholder="Comma Seperated Email Addresses" autocomplete="off" [(ngModel)]="saveUserParams.details.report_emails"></textarea>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="saveUserParams.show_address">
          <div class="col-md-12">
            <label>ADDRESS</label>
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Line 1" autocomplete="off" [(ngModel)]="saveUserParams.details.address.line1" />
              <input type="text" class="form-control" placeholder="Line 2" autocomplete="off" [(ngModel)]="saveUserParams.details.address.line2" />
              <input type="text" class="form-control" placeholder="Line 3" autocomplete="off" [(ngModel)]="saveUserParams.details.address.line3" />
              <input type="text" class="form-control" placeholder="Line 4" autocomplete="off" [(ngModel)]="saveUserParams.details.address.line4" />
              <input type="text" class="form-control" placeholder="Line 5" autocomplete="off" [(ngModel)]="saveUserParams.details.address.line5" />
            </div>
          </div>
        </div>
        <div class="row" *ngIf="saveUserParams.show_tat">
          <div class="col-md-12">
            <label>TAT</label>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <input type="number" class="form-control" autocomplete="off" [(ngModel)]="saveUserParams.details.tat.value" />
                </div>
                <div class="col-md-6">
                  <select class="form-select select-dd fit-dd" [(ngModel)]="saveUserParams.details.tat.unit">
                    <option *ngFor="let unit of saveUserParams.units" [value]="unit._id"> {{ unit.name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row sub-header-buffer">
          <div class="col-md-12 text-center">
            <button class="btn btn-ecom px-5" (click)="saveUser(saveUserParams)">
              {{ saveUserParams.btn_txt }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="resetModal" tabindex="-1" role="dialog"
  aria-labelledby="resetModalabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>RESET PASSWORD</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close"
              style="cursor: pointer">&times;</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>PASSWORD</label>
            <div class="form-group">
              <input type="text" class="form-control" autocomplete="off" [(ngModel)]="resetUserParams.resetPass" />
            </div>
          </div>
        </div>
        <div class="row sub-header-buffer">
          <div class="col-md-12 text-center">
            <button class="btn btn-ecom px-5" (click)="resetPassword(resetUserParams)">
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-fcm-notification [params]="fcmNotificationParams"></app-fcm-notification>
