import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ShipmentService } from '../../services/api/shipment.service';
import { Router } from '@angular/router';
import { UtilityService } from '../../services/utility.service';
import { CONSTANTS } from '../../constants';
import { NotificationService } from '../../services/notification.service';
import { DateUtil } from '../../utils/date.utils';
import { DatePipe } from '@angular/common';
import { JqueryUtils } from '../../utils/jquery.utils';

@Component({
  selector: 'app-reverse-pickup-view',
  templateUrl: './reverse-pickup-view.component.html',
  styleUrls: ['./reverse-pickup-view.component.scss']
})
export class ReversePickupViewComponent implements OnInit, OnDestroy {

  sess_acc: any;
  token: any;
  searchTxt: any;
  shipments: any = [];
  offset: any = 0;
  totalItems: any = 0;
  shipmentType: any = '';
  selected_tab: any = '';
  default_tab_txt: any = {
    picked_from_customer: 'Picked',
    rp_delivered: 'Delivered',
  };
  fcmNotificationParams: any = { header: '', msg: '' };
  tab_txt: any = {};
  subscriber_id: any = 'rp-view';
  any_selected: any = false;

  constructor(private dataService: DataService, private shipmentService: ShipmentService, private ns: NotificationService,
    private utilityService: UtilityService, private dateUtils: DateUtil, private router: Router, private dp: DatePipe,
    private jq: JqueryUtils) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.token = sessionStorage.getItem(CONSTANTS.COOKIE.HEADER);
    for (let key in this.default_tab_txt) {
      this.tab_txt[key] = this.default_tab_txt[key];
    }
    this.dataService.onRPShipmentListPageSelected(this.subscriber_id).subscribe(res => {
      this.loadShipments(res.offset);
    });
    this.dataService.onRPShipmentListTabSelected(this.subscriber_id).subscribe(res => {
      this.loadShipments(res.offset);
    });
    this.dataService.onRPShipmentSelected(this.subscriber_id).subscribe(res => {
      this.any_selected = res.any_selected;
    });
    this.dataService.onFcmNotificationReceived(this.subscriber_id).subscribe(res => {
      this.fcmNotificationParams = {
        header: res.data.data_title,
        msg: res.data.data_body
      };
      this.jq.openModal('fcmNotificationModal');
    });
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
    setTimeout(() => {
      let selected: any = this.dataService.getSelectedTab();
      if (!selected) {
        this.onTabOptionSelected(CONSTANTS.RP_SHIPMENT_TAB.PICKED);
      } else {
        this.onTabOptionSelected(selected.tab);
      }
    }, 100);
  }

  assignShipments() {
    this.dataService.rpAssignShipments({});
  }

  loadShipments(offset: any) {
    if ((this.searchTxt != null) && (this.searchTxt.trim() != "")) {
      this.searchShipment();
    } else {
      this.getShipments(offset, CONSTANTS.ITEM_PER_PAGE);
    }
  }

  refreshShipments() {
    this.loadShipments(this.offset);
  }

  clearSearch() {
    this.searchTxt = ""
    this.loadShipments(0);
  }

  getShipments(offset: any, limit: any) {
    this.offset = offset;
    let input: any = {
      "offset": offset,
      "limit": limit,
      "shipment_type": "reverse_pickup",
      "state": this.shipmentType
    };
    if ([CONSTANTS.RP_SHIPMENT_TYPE.DELIVERED].indexOf(this.shipmentType) > -1) {
      var date = new Date();
      input['dt'] = this.dp.transform(date,"yyyy-MM-dd")
    }
    this.shipmentService.getRPShipments(input).subscribe((response: any) => {
      this.shipments = [];
      this.shipments = response.shipments;
      this.totalItems = response.count;
      this.setTabText();
    });
  }

  searchShipment() {
    if (!this.searchTxt || (this.searchTxt == null) || (this.searchTxt.trim() == '')) return;
    let input = {
      "order_no": this.searchTxt,
      "dt": "mobile_detail_view"
    };
    this.shipmentService.search(input).subscribe((response: any) => {
      this.shipments = [];
      if ((response.shipment != null) && (response.shipment.shipment_type == CONSTANTS.SHIPMENT_FLOWS.RP)) {
        this.shipments.push(response.shipment);
        this.totalItems = 1;
        this.selected_tab = (response.shipment.shipment_status == CONSTANTS.RP_SHIPMENT_TYPE.PICKED_FROM_CUSTOMER) ? CONSTANTS.RP_SHIPMENT_TYPE.PICKED_FROM_CUSTOMER : CONSTANTS.RP_SHIPMENT_TYPE.DELIVERED;
      } else {
        this.ns.showAlert(CONSTANTS.ERROR, "Waybill doesn't exist");
        this.totalItems = 0;
      }
      this.shipmentType = this.getShipmentType();
      this.setTabText();
    });
  }

  onTabSelected(selected_tab: any) {
    this.selected_tab = selected_tab;
    this.shipmentType = this.getShipmentType();
    this.dataService.rpShipmentListTabSelected({offset: 0, count: CONSTANTS.ITEM_PER_PAGE, shipment_type: this.shipmentType});
  }

  onTabOptionSelected(selected_tab: any) {
    this.onTabSelected(selected_tab);
  }

  setActiveTab(tab: any) {
    if (this.selected_tab == tab) {
      return { active: 1 };
    }
    return { active: 0 };
  }

  setTabText() {
    let txt: any = {
      picked_from_customer: 'Picked',
      rp_delivered: 'Delivered',
    };
    this.tab_txt[this.selected_tab] = `${txt[this.selected_tab]}(${this.totalItems})`;
  }

  getShipmentType() {
    let shipment_type: any = "";
    switch(this.selected_tab) {
      case CONSTANTS.RP_SHIPMENT_TAB.PICKED:
        shipment_type = CONSTANTS.RP_SHIPMENT_TYPE.PICKED_FROM_CUSTOMER;
        break
      case CONSTANTS.RP_SHIPMENT_TAB.DELIVERED:
        shipment_type = CONSTANTS.RP_SHIPMENT_TYPE.DELIVERED;
        break
    }
    return shipment_type;
  }

  ngOnDestroy(): void {
    this.dataService.onRPShipmentListPageSelected(this.subscriber_id);
    this.dataService.onRPShipmentListTabSelected(this.subscriber_id);
    this.dataService.onRPShipmentSelected(this.subscriber_id);
    this.dataService.offFcmNotificationReceived(this.subscriber_id);
    this.dataService.offLoggedOut(this.subscriber_id);
  }
}
