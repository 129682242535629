import { AccountService } from '../../services/api/account.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '../../constants';
import { NotificationService } from '../../services/notification.service';
import { JqueryUtils } from '../../utils/jquery.utils';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utility.service';
import { DistrictService } from '../../services/api/district.service';
import { StateService } from '../../services/api/state.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {

  sess_acc: any;
  offset = 0;
  count = 20;
  totalItems = 0;
  accounts: any = [];
  nps: any = [];
  deleteUserParams: any = {
    selectedUserToDelete: { }
  };
  saveUserParams: any = {
    header: '',
    btn_txt: '',
    user: { }
  };
  resetUserParams: any = {
    selectedUserToReset: {},
    resetPass: ''
  };
  fcmNotificationParams: any = { header: '', msg: '' };
  all_user_types: any = [
    // {_id: 0, name: 'Subadmin', tag: CONSTANTS.USER_TYPES.SUB_ADMIN, uts: [CONSTANTS.USER_TYPES.ADMIN] },
    // {_id: 1, name: 'Field Executive', tag: CONSTANTS.USER_TYPES.FIELD_EXEC },
    {_id: 2, name: 'Network Partner', tag: CONSTANTS.USER_TYPES.NETWORK_PARTNER, uts: [CONSTANTS.USER_TYPES.ADMIN, CONSTANTS.USER_TYPES.SUB_ADMIN] },
    // {_id: 3, name: 'Delivery Partner', tag: CONSTANTS.USER_TYPES.NETWORK_PARTNER_SUB_ADMIN, uts: [CONSTANTS.USER_TYPES.NETWORK_PARTNER] },
    {_id: 4, name: 'Field Executive', tag: CONSTANTS.USER_TYPES.NETWORK_PARTNER_FIELD_EXEC, uts: [CONSTANTS.USER_TYPES.NETWORK_PARTNER, CONSTANTS.USER_TYPES.NETWORK_PARTNER_SUB_ADMIN] },
    {_id: 5, name: 'Client', tag: CONSTANTS.USER_TYPES.CLIENT, uts: [CONSTANTS.USER_TYPES.ADMIN, CONSTANTS.USER_TYPES.SUB_ADMIN] },
  ];
  user_types: any = [];
  selUserType: any = this.all_user_types[0]._id;

  executive_types: any = [
    {_id: 0, name: 'Both', tag: "both"},
    {_id: 1, name: 'Pickup', tag: "pickup_executive" },
    {_id: 2, name: 'Delivery', tag: "delivery_executive"},
  ];

  states: any = [];
  locations: any = [];
  np_uts: any = [];
  exts_uts: any = [CONSTANTS.USER_TYPES.NETWORK_PARTNER_FIELD_EXEC];
  locality_uts: any = [CONSTANTS.USER_TYPES.CLIENT, CONSTANTS.USER_TYPES.NETWORK_PARTNER,
    CONSTANTS.USER_TYPES.NETWORK_PARTNER_SUB_ADMIN];

  tableParams: any = {};
  searchTxt: any;
  subscriber_id: any = 'user';

  constructor(private accountService: AccountService, private dataService: DataService,
    private districtService: DistrictService, private stateService: StateService,
    private ns: NotificationService, private utilityService: UtilityService,
    private router: Router, private jq: JqueryUtils) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.filterUserTypes();
    this.loadUsers();
    let user_type = this.getUserTypeByTag(CONSTANTS.USER_TYPES.NETWORK_PARTNER);
    this.createSaveUserParams({edit: false, data: {details: { tat: { }, address: { } }, user: {}}}, user_type);
    this.loadStates(() => {
      this.loadLocations(null, null);
    });
    this.dataService.onFcmNotificationReceived(this.subscriber_id).subscribe(res => {
      this.fcmNotificationParams = {
        header: res.data.data_title,
        msg: res.data.data_body
      };
      this.jq.openModal('fcmNotificationModal');
    });
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
  }

  openDeleteUserModal(data: any) {
    this.deleteUserParams = {
      selectedUserToDelete: data
    };
    this.jq.openModal('delUserModal');
  }

  deleteUser(params: any) {
    let input = {
      "id": params.selectedUserToDelete.user.user_id
    };
    this.accountService.deleteAccounts(input).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "User deleted successfully.");
      this.loadUsers();
      this.deleteUserParams = {
        selectedUserToDelete: {}
      };
      this.jq.closeModal('delUserModal');
    });
  }

  openResetPasswordModal(data: any) {
    this.resetUserParams = {
      selectedUserToReset: data
    };
    this.jq.openModal('resetModal');
  }

  resetPassword(params: any) {
    let input = {
      pwd: params.resetPass,
      user_id: params.selectedUserToReset.user.user_id
    }
    this.accountService.resetPassword(input).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "Password reset successfully.");
      this.loadUsers();
      this.resetUserParams = {
        selectedUserToReset: {},
        resetPass: ''
      };
      this.jq.closeModal('resetModal');
    });
  }

  onPageSelected(currentPage: any) {
    this.offset = currentPage * 20;
    this.loadUsers();
  }

  loadUsers() {
    let user_type: any = this.getUserTypeById(this.selUserType);
    if (!user_type) {
      return;
    }
    let input = {
      "index": this.offset,
      "limit": 20,
      "user_type": user_type.tag,
      "offset": this.offset
    };
    this.accountService.getAccounts(input).subscribe((response: any) => {
      this.accounts = [];
      this.accounts = response.accounts;
      this.totalItems = response.count;
      this.calculateTableParams();
    });
  }

  loadStates(cb: any) {
    this.states = [];
    this.stateService.getStates().subscribe((response: any) => {
      let states: any = [];
      response.states.forEach((state: any, idx: any) => {
        states.push({_id: idx, name: state, tag: state.toLowerCase() })
      });
      this.states = states;
      if (cb) {
        cb(states);
      }
    });
  }

  loadLocations(cb: any, state: any) {
    this.locations = [];
    let ip: any = { state: (state == null) ? this.states[0].name : state.name };
    this.districtService.getDistricts(ip).subscribe((response: any) => {
      let locations: any = [];
      response.districts.forEach((district: any, idx: any) => {
        locations.push({_id: idx, name: district, tag: district.toLowerCase() })
      });
      this.locations = locations;
      if (cb) {
        cb(locations);
      }
    });
  }

  filterUserTypes() {
    let user_types: any = [];
    for (let idx = 0; idx < this.all_user_types.length; idx++) {
      if (this.all_user_types[idx].uts.indexOf(this.sess_acc.user_type) > -1) {
        user_types.push(this.all_user_types[idx]);
      }
    }
    this.user_types = user_types;
    this.selUserType = this.user_types[0]._id;
  }

  calculateTableParams() {
    let user_type: any = this.getUserTypeById(this.selUserType);
    let show_locality: any = (user_type != null) && user_type && (user_type.tag == 'np');
    let snp_uts: any = [CONSTANTS.USER_TYPES.NETWORK_PARTNER_SUB_ADMIN, CONSTANTS.USER_TYPES.NETWORK_PARTNER_FIELD_EXEC];
    let set_uts: any = [CONSTANTS.USER_TYPES.NETWORK_PARTNER_FIELD_EXEC];
    this.tableParams = {
      show_locality: show_locality,
      show_np: snp_uts.indexOf(this.sess_acc.user_type) > -1,
      show_et: set_uts.indexOf(user_type.tag) > -1,
    };
  }

  openAddUserModal() {
    this.openSaveUserModal(false, { details: { tat: {} }, user: {} });
  }

  openUpdateUserModal(data: any) {
    this.openSaveUserModal(true, data);
  }

  openSaveUserModal(edit: any, data: any) {
    let user_type: any = this.getUserTypeById(this.selUserType);
    if (!user_type) {
      this.ns.showAlert(CONSTANTS.ERROR, "Please select atleast one user type.");
      return;
    }
    let exts: any = (this.exts_uts.indexOf(user_type.tag) > -1) ? this.executive_types : [];
    let locations: any = ((this.locality_uts.indexOf(user_type.tag) > -1) && !edit) ? this.locations : [];
    let states: any = ((this.locality_uts.indexOf(user_type.tag) > -1) && !edit) ? this.states : [];
    if ((this.np_uts.indexOf(user_type.tag) > -1) && !edit) {
      let ip: any = { user_type: 'np' };
      this.accountService.getAccounts(ip).subscribe((response: any) => {
        this.nps = response.accounts;
        this.createSaveUserParams({edit, data}, user_type, this.nps, exts, states, locations);
        this.jq.openModal('saveUserModal');
      });
    } else {
      this.createSaveUserParams({edit, data}, user_type, [], exts, states, locations);
      this.jq.openModal('saveUserModal');
    }
  }

  closeSaveUserModal() {
    this.saveUserParams = { details: { tat: {} }, user: { } };
    this.jq.closeModal('saveUserModal');
  }

  createSaveUserParams(opts: any, user_type: any, nps: any = [], executive_types: any = [], states: any = [], locations: any = []) {
    let selectedStateId: any = "", selectedLocationId: any = "", selectedExecTypeId: any = "", selectedNpId: any = "";
    if (states.length > 0) {
      let state: any = this.getStateByName(opts.data.details?.state);
      if (state) {
        selectedStateId = state._id;
      } else {
        selectedStateId = states[0]._id;
      }
    }
    if (locations.length > 0) {
      let location: any = this.getLocalityByName(opts.data.details?.locality);
      if (location) {
        selectedLocationId = location._id;
      } else {
        selectedLocationId = locations[0]._id;
      }
    }
    if (executive_types.length > 0) {
      let executive_type: any = this.getExecTypeByTag(opts.data.details?.type);
      if (executive_type) {
        selectedExecTypeId = executive_type._id;
      } else {
        selectedExecTypeId = executive_types[0]._id;
      }
    }
    if (nps.length > 0) {
      let np: any = this.getNPById(opts.data.details?.np_id);
      if (np) {
        selectedNpId = np.account_id;
      } else {
        selectedNpId = nps[0].account_id;
      }
    } else {
      if ([CONSTANTS.USER_TYPES.NETWORK_PARTNER_FIELD_EXEC, CONSTANTS.USER_TYPES.NETWORK_PARTNER_SUB_ADMIN].indexOf(user_type?.tag) > -1) {
        selectedNpId = this.sess_acc.account_id;
      }
    }
    let units: any = [{ _id: 'hrs', name: 'hrs'}, { _id: 'days', name: 'days'}];
    opts.data.details.address = opts.data.details.address || { };
    opts.data.details.tat = opts.data.details.tat || { value: 1, unit: units[0]._id };
    this.saveUserParams = {
      header: opts.edit ? 'Update User': 'Add User',
      btn_txt: opts.edit ? 'UPDATE' : 'ADD',
      user_type: user_type?.tag,
      nps: nps,
      units: units,
      executive_types: executive_types,
      states: states,
      locations: locations,
      selectedNpId: selectedNpId,
      selectedExecTypeId: selectedExecTypeId,
      selectedStateId: selectedStateId,
      selectedLocationId: selectedLocationId,
      show_np: (nps.length > 0),
      show_fe_type: (executive_types.length > 0),
      show_locality: (locations.length > 0),
      show_address: (user_type?.tag == 'client'),
      show_tat: (user_type?.tag == 'client'),
      details: opts.data.details,
      user: {
        _id: opts.data.user.user_id || '',
        full_name: opts.data.user.full_name || '',
        email: opts.data.user.email || '',
        phone: opts.data.user.phone || '',
        password: ''
      },
      edit: opts.edit
    };
  }

  searchUser() {
    if (!this.searchTxt || (this.searchTxt == null) || (this.searchTxt.trim() == '')) return;
    let user_type: any = this.getUserTypeById(this.selUserType);
    if (!user_type) {
      this.ns.showAlert(CONSTANTS.ERROR, "Please select atleast one user type.");
      return;
    }
    let ip: any = { search_str: this.searchTxt, user_type: user_type.tag };
    this.accountService.searchUser(ip).subscribe((response: any) => {
      this.accounts = [];
      this.accounts = response.accounts;
    });
  }

  saveUser(params: any) {
    params = JSON.parse(JSON.stringify(params))
    let ip: any = {};
    let state_tag: any = "", locality_tag: any = "", np_id: any = "", exec_type_tag: any = "";
    if ((this.locality_uts.indexOf(params.user_type) > -1) && !params.edit) {
      let state: any = this.getStateById(params.selectedStateId);
      if (!state) {
        this.ns.showAlert(CONSTANTS.ERROR, "Please select city.");
        return;
      }
      state_tag = state.tag;
    } else {
      if (params.user_type == CONSTANTS.USER_TYPES.NETWORK_PARTNER_FIELD_EXEC) {
        state_tag = this.sess_acc.details?.state;
      }
    }
    if ((this.locality_uts.indexOf(params.user_type) > -1) && !params.edit) {
      let locality: any = this.getLocalityById(params.selectedLocationId);
      if (!locality) {
        this.ns.showAlert(CONSTANTS.ERROR, "Please select city.");
        return;
      }
      locality_tag = locality.tag;
    } else {
      if (params.user_type == CONSTANTS.USER_TYPES.NETWORK_PARTNER_FIELD_EXEC) {
        locality_tag = this.sess_acc.details?.locality;
      }
    }
    if ((this.np_uts.indexOf(params.user_type) > -1) && !params.edit) {
      let np: any = this.getNPById(params.selectedNpId);
      if (!np) {
        this.ns.showAlert(CONSTANTS.ERROR, "Please select Network Partner.");
        return;
      }
      np_id = np.account_id;
    } else {
      np_id = params.selectedNpId;
    }
    if (params.details.np) {
      delete params.details.np;
    }
    if (this.exts_uts.indexOf(params.user_type) > -1) {
      let ext: any = this.getExecTypeById(params.selectedExecTypeId);
      if (!ext) {
        this.ns.showAlert(CONSTANTS.ERROR, "Please select Field Executive Type.");
        return;
      }
      exec_type_tag = ext.tag;
    }
    if (params.edit) {
      ip = {
        id: params.user._id,
        user: {
          full_name: params.user.full_name,
          email: params.user.email,
          phone: params.user.phone,
          details: {}
        },
        details: params.details,
      };
    } else {
      ip = {
        user: {
          full_name: params.user.full_name,
          email: params.user.email,
          phone: params.user.phone,
          password: params.user.password,
          details: {}
        },
        details: params.details,
        user_type: params.user_type
      };
    }
    if (state_tag != "") {
      ip.state = state_tag;
    }
    if (locality_tag != "") {
      ip.locality = locality_tag;
    }
    if (np_id != "") {
      ip.np_id = np_id;
    }
    if (exec_type_tag != "") {
      ip.details.type = exec_type_tag;
    }
    let func: any = params.edit ? this.accountService.updateAccounts : this.accountService.addAccounts;
    func(ip).subscribe((response: any) => {
      if (params.edit) {
        this.ns.showAlert(CONSTANTS.SUCCESS, "User updated successfully")
      } else {
        this.ns.showAlert(CONSTANTS.SUCCESS, "User added successfully")
      }
      this.loadUsers();
      let user_type = this.getUserTypeByTag(CONSTANTS.USER_TYPES.SUB_ADMIN);
      this.createSaveUserParams({edit: false, data: { details: { tat: { }, address : { } }, user:{}}}, user_type);
      this.jq.closeModal('saveUserModal');
    });
  }

  onSelectedUserType() {
    this.loadUsers();
  }

  onSaveUserStateSelected(params: any) {
    let state: any = this.getStateById(params.selectedStateId)
    this.loadLocations((locations: any) => {
      params.locations = locations;
    }, state);
  }

  onSaveUserExecTypeSelected() { }

  onSaveUserLocationSelected() { }

  onSaveUserNPSelected() { }

  get_exec_type_name(tag: any) {
    let exc_type: any = this.getExecTypeByTag(tag);
    if (exc_type) {
      return exc_type.name;
    }
    return "";
  }

  getUserTypeById(user_type_id: any) {
    for (let idx = 0; idx < this.user_types.length; idx++) {
      if (this.user_types[idx]._id == user_type_id) {
        return this.user_types[idx];
      }
    }
  }

  getUserTypeByTag(tag: any) {
    for (let idx = 0; idx < this.user_types.length; idx++) {
      if (this.user_types[idx].tag == tag) {
        return this.user_types[idx];
      }
    }
  }

  getStateById(state_id: any) {
    for (let idx = 0; idx < this.states.length; idx++) {
      if (this.states[idx]._id == state_id) {
        return this.states[idx];
      }
    }
  }

  getLocalityById(locality_id: any) {
    for (let idx = 0; idx < this.locations.length; idx++) {
      if (this.locations[idx]._id == locality_id) {
        return this.locations[idx];
      }
    }
  }

  getNPById(np_id: any) {
    for (let idx = 0; idx < this.nps.length; idx++) {
      if (this.nps[idx].account_id == np_id) {
        return this.nps[idx];
      }
    }
  }

  getExecTypeById(et_id: any) {
    for (let idx = 0; idx < this.executive_types.length; idx++) {
      if (this.executive_types[idx]._id == et_id) {
        return this.executive_types[idx];
      }
    }
  }

  getStateByName(name: any) {
    for (let idx = 0; idx < this.states.length; idx++) {
      if (this.states[idx].name.toLowerCase() == name?.toLowerCase()) {
        return this.states[idx];
      }
    }
  }

  getLocalityByName(name: any) {
    for (let idx = 0; idx < this.locations.length; idx++) {
      if (this.locations[idx].name.toLowerCase() == name?.toLowerCase()) {
        return this.locations[idx];
      }
    }
  }

  getExecTypeByTag(tag: any) {
    for (let idx = 0; idx < this.executive_types.length; idx++) {
      if (this.executive_types[idx].tag.toLowerCase() == tag?.toLowerCase()) {
        return this.executive_types[idx];
      }
    }
  }

  clearSearch() {
    this.searchTxt = '';
    this.loadUsers();
  }

  ngOnDestroy(): void {
    this.dataService.offFcmNotificationReceived(this.subscriber_id);
    this.dataService.offLoggedOut(this.subscriber_id);
  }
}
