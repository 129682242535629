<div class="container px-0">
  <div class="row sub-header-buffer">
    <div class="col-md-3">
      <input type="date" name="manifest_date" class="form-control" [(ngModel)]="selDate" (change)="onDateChanged(false)">
    </div>
    <div class="col-md-3" *ngIf="allow.fe_selection">
      <select class="form-select select-dd h-100" (change)="onSelectedManifestFE($event)">
        <option *ngFor="let fe of manifest_fes" [selected]="fe.selected" [value]="fe.account_id"> {{ fe.user.full_name }}</option>
      </select>
    </div>
    <div class="col-md-3">
      <button type="button" class="btn btn-ecom" (click)="refreshManifests()">Refresh</button>
    </div>
  </div>
  <div class="row sub-header-buffer">
    <table class="table ecom-table">
      <thead class="table-header">
        <tr>
          <th>
            <div>DATE</div>
          </th>
          <th>
            <div>FE NAME</div>
          </th>
          <th>
            <div>STATE</div>
          </th>
          <th>ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let manifest of manifests">
          <td>
            <div>{{manifest.date}}</div>
          </td>
          <td>
            <div>{{manifest.fe?.user?.full_name}}</div>
          </td>
          <td>
            <div>{{manifest.state}}</div>
          </td>
          <td>
            <div class="row">
              <div class="col">
                <i class="fas fa-check text-success mx-1" (click)="openManifestGeneratedModal()" *ngIf="manifest.state == 'closed'">
                </i>
                <i class="fas fa-file-excel text-primary mx-1" (click)="closeManifest(manifest)"
                  *ngIf="can_close(manifest)">
                </i>
                <i class="fas fa-exclamation text-danger mx-1" *ngIf="manifest.details.status != 'completed'">
                </i>
                <i class="fas fa-eye text-primary mx-1" (click)="viewShipments(manifest)">
                </i>
                <i class="fas fa-download text-primary mx-1" (click)="downloadShipments(manifest)">
                </i>
                </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<div class="modal fade" data-backdrop="static" data-keyboard="false" id="rpManifestGeneratedModal" tabindex="-1" role="dialog"
  aria-labelledby="rpManifestGeneratedModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>Info</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close"
              style="cursor: pointer" (click)="closeManifestGeneratedModal()">&times;</span>
          </div>
        </div>
        <div class="row pt-3">
            <label>The Manifest is generated already. Please click on View or Download to check the shipments.</label>
        </div>
        <div class="row sub-header-buffer">
          <button class="btn btn-block btn-ecom ml-2" (click)="closeManifestGeneratedModal()">
              OK
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-fcm-notification [params]="fcmNotificationParams"></app-fcm-notification>
