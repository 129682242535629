<div class="container ml-0">
    <div class="row header-buffer header-buffer-pt">
        <div class="col-md-12 d-flex">
            <h4>CURRENTLY AS ON {{seldate}}</h4>
            <button type="button" class="btn btn-light refresh-btn" (click)="onRefresh()">
                <i class="fas fa-arrows-rotate"></i>
            </button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12 d-flex">
            <h6>FORWARD DELIVERY</h6>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-3 d-flex" *ngIf="allow.booking" (click)="navigate('forward', 'booking_created')">
            <button type="button" class="btn btn-dashboard">
                Booking <span class="badge badge-light dashboard-badge">{{ count_map.booking_created }}</span>
            </button>
            <a class="d-flex i-align"><i class="fas fa-arrow-right"></i></a>
        </div>
        <div class="col-md-3 d-flex" *ngIf="allow.pe_assigned" (click)="navigate('forward', 'pickup_executive_assigned')">
            <button type="button" class="btn btn-dashboard">
                Executive Assigned <span class="badge badge-light dashboard-badge">{{ count_map.pickup_executive_assigned }}</span>
            </button>
            <a class="d-flex i-align"><i class="fas fa-arrow-right"></i></a>
        </div>
        <div class="col-md-3 d-flex" *ngIf="allow.picked_from_wh" (click)="navigate('forward', 'picked_from_warehouse')">
            <button type="button" class="btn btn-dashboard">
                Pickup From Warehouse <span class="badge badge-light dashboard-badge">{{ count_map.picked_from_warehouse }}</span>
            </button>
            <a class="d-flex i-align"><i class="fas fa-arrow-right"></i></a>
        </div>
        <div class="col-md-3 d-flex" *ngIf="allow.arrived_at_hub" (click)="navigate('forward', 'arrived_at_hub')">
            <button type="button" class="btn btn-dashboard">
                Arrived At Hub <span class="badge badge-light dashboard-badge">{{ count_map.arrived_at_hub }}</span>
            </button>
            <a class="d-flex i-align"><i class="fas fa-arrow-right"></i></a>
        </div>
        <div class="col-md-3 d-flex pt-2" (click)="navigate('forward', 'intransit')">
            <button type="button" class="btn btn-dashboard">
                Intransit <span class="badge badge-light dashboard-badge">{{ count_map.intransit }}</span>
            </button>
            <a class="d-flex i-align"><i class="fas fa-arrow-right"></i></a>
        </div>
        <div class="col-md-3 d-flex pt-2" (click)="navigate('forward', 'arrived_at_destination_hub')">
            <button type="button" class="btn btn-dashboard">
                At Destination Hub <span class="badge badge-light dashboard-badge">{{ count_map.arrived_at_destination_hub }}</span>
            </button>
            <a class="d-flex i-align"><i class="fas fa-arrow-right"></i></a>
        </div>
        <div class="col-md-3 d-flex pt-2" (click)="navigate('forward', 'out_for_delivery')">
            <button type="button" class="btn btn-dashboard">
                Out For Delivery <span class="badge badge-light dashboard-badge">{{ count_map.out_for_delivery }}</span>
            </button>
            <a class="d-flex i-align"><i class="fas fa-arrow-right"></i></a>
        </div>
        <div class="col-md-3 d-flex pt-2" (click)="navigate('forward', 'delivered')">
            <button type="button" class="btn btn-dashboard">
                Delivered <span class="badge badge-light dashboard-badge">{{ count_map.delivered }}</span>
            </button>
            <a class="d-flex i-align"><i class="fas fa-arrow-right"></i></a>
        </div>
        <div class="col-md-3 d-flex pt-2" (click)="navigate('forward', 'rejected')">
            <button type="button" class="btn btn-dashboard">
                Rejected <span class="badge badge-light dashboard-badge">{{ count_map.rejected }}</span>
            </button>
            <a class="d-flex i-align"><i class="fas fa-arrow-right"></i></a>
        </div>
        <div class="col-md-3 d-flex pt-2" (click)="navigate('forward', 'pending')">
            <button type="button" class="btn btn-dashboard">
                Pending <span class="badge badge-light dashboard-badge">{{ count_map.pending }}</span>
            </button>
            <a class="d-flex i-align"><i class="fas fa-arrow-right"></i></a>
        </div>
        <div class="col-md-3 d-flex pt-2" (click)="navigate('forward', 'cancel')">
            <button type="button" class="btn btn-dashboard">
                Cancelled <span class="badge badge-light dashboard-badge">{{ count_map.cancel }}</span>
            </button>
            <a class="d-flex i-align"><i class="fas fa-arrow-right"></i></a>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12 d-flex">
            <h6>REVERSE PICKUP</h6>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-3 d-flex" *ngIf="allow.picked_from_customer" (click)="navigate('rp', 'picked_from_customer')">
            <button type="button" class="btn btn-dashboard">
                Picked From Customer <span class="badge badge-light dashboard-badge">{{ rp_count_map.picked_from_customer }}</span>
            </button>
            <a class="d-flex i-align"><i class="fas fa-arrow-right"></i></a>
        </div>
        <div class="col-md-3 d-flex" *ngIf="allow.rp_delivered" (click)="navigate('rp', 'rp_delivered')">
            <button type="button" class="btn btn-dashboard">
                Delivered <span class="badge badge-light dashboard-badge">{{ rp_count_map.rp_delivered }}</span>
            </button>
            <a class="d-flex i-align"><i class="fas fa-arrow-right"></i></a>
        </div>
    </div>
    <div class="row header-buffer">
        <div class="col-md-12">
            <h4>TRACK IT</h4>
        </div>
    </div>
    <div class="row sub-header-buffer">
        <div class="col-md-8">
            <div class="form-group">
                <input type="text" class="form-control" [(ngModel)]="searchTxt" placeholder="Search by AWB No" />
            </div>
        </div>
        <div class="col-md-2">
            <button type="button" class="btn btn-ecom px-5" (click)="searchShipment()">
                Search
            </button>
        </div>
        <div class="col-md-2">
            <button type="button" class="btn btn-ecom px-5" (click)="clearSearch()">
                Clear
            </button>
        </div>
    </div>
    <div class="row sub-header-buffer"*ngFor="let shipment of shipments">
        <div class="col">
            <div class="card bg-yellow shadow">
                <div class="card-body">
                    <div class="row">
                        <div class="col d-flex">
                            <span class="font-weight-bold details-header flex-grow-1"> Booking Details for AWB No: 
                                <span class="text-theme">{{ get_awb_no(shipment) }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="row sub-header-buffer">
                        <div class="col-3 border border-right-0 details-col details-col-title">
                            <span>Client Name</span>
                        </div>
                        <div class="col-3 border border-right-0 details-col details-col-text">
                            <span>{{ get_client_name(shipment) }}</span>
                        </div>
                        <div class="col-3 border border-right-0 details-col details-col-title">
                            <span>Reference No</span>
                        </div>
                        <div class="col-3 border details-col details-col-text">
                            <span>{{ get_ref_name(shipment) }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 border border-right-0 border-top-0 details-col details-col-title">
                            <span>Booking Place</span>
                        </div>
                        <div class="col-3 border border-right-0 border-top-0 details-col details-col-text">
                            <span>{{ get_booking_place(shipment) }}</span>
                        </div>
                        <div class="col-3 border border-right-0 border-top-0 details-col details-col-title">
                            <span>Delivery Place</span>
                        </div>
                        <div class="col-3 border border-top-0 details-col details-col-text">
                            <span>{{ get_delivery_place(shipment) }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 border border-right-0 border-top-0 details-col details-col-title">
                            <span>Booking Date & Time</span>
                        </div>
                        <div class="col-3 border border-right-0 border-top-0 details-col details-col-text">
                            <span>{{ shipment.created_date }}</span>
                        </div>
                        <div class="col-3 border border-right-0 border-top-0 details-col details-col-title">
                            <span>Delivery Date & Time</span>
                        </div>
                        <div class="col-3 border border-top-0 details-col details-col-text">
                            <span>{{ get_delivery_dt(shipment) }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 border border-right-0 border-top-0 details-col details-col-title">
                            <span>Sign/Photo</span>
                        </div>
                        <div class="col-3 border border-right-0 border-top-0 details-col details-col-text">
                            <a href="javascript:void(0)" *ngIf="can_show_sign(shipment)"
                                (click)="downloadSign(shipment)">View Sign</a>
                            <span *ngIf="!can_show_sign(shipment)">NO Sign</span>
                            <span> / </span>
                            <a href="javascript:void(0)" *ngIf="can_show_pod(shipment)"
                            (click)="downloadPOD(shipment)">View Photo</a>
                            <span *ngIf="!can_show_pod(shipment)">NO Photo</span>
                        </div>
                        <div class="col-3 border border-right-0 border-top-0 details-col details-col-title">
                            <span>Shipment Status</span>
                        </div>
                        <div class="col-3 border border-top-0 details-col details-col-text">
                            <span>{{ get_display_status(shipment) }}</span>
                        </div>
                    </div>
                    <div class="row sub-header-buffer">
                        <div class="col">
                            <table class="table border ecom-table">
                                <thead class="table-header">
                                    <tr>
                                        <th class="border text-light">Consignor</th>
                                        <th class="border text-light">Consignee</th>
                                        <th class="border text-light">Weight</th>
                                        <th class="border text-light">No Of Boxes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let job of shipments">
                                        <td class="border">{{ shipment.created_for?.user?.full_name }}</td>
                                        <td class="border">{{ shipment.details?.store }}</td>
                                        <td class="border">{{ shipment.details?.total_weight }}</td>
                                        <td class="border">{{ shipment.details?.total_box }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-fcm-notification [params]="fcmNotificationParams"></app-fcm-notification>
